import React from 'react';
import { Link } from 'react-router-dom';

function Thank_you() {
    return (
        <div className="flexitems-center">
            <div>
                <div className="flex flex-col items-center space-y-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width={130} height={130} color='#0be40b' className="text-green-600 w-28 h-28" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={1}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <h1 className="text-4xl font-bold">Thank You !</h1>
                    <p style={{fontSize:"20px"}}>We appreciate you for giving us a chance.
                        Our team will get back to you and schedule the meeting for the same.</p>
                    <a href="/" className="btn btn-primary acenna_backHome">Back Home</a>
                </div>
            </div>
        </div>
    )
}

export default Thank_you;
