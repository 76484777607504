import React from 'react';
import FooterPart from '../FooterPart';
import HeadPart from '../HeadPart';

function OurTeam() {
  return (
    <>
        <HeadPart />
      <header id="header" className="hc-cmp-header hc-component header-imagePass" style={{ backgroundImage: 'url(../wp-content/uploads/2019/06/hd-wide-1-1920x500.jpg)' }}>
        <div className="container someheaderBanner">
          <h1 className='policymngChanges'>Our Team</h1>
          {/* <ol className="breadcrumb ">
            <li><a href="/">Home</a></li>
            <li className="active"><a href="#">Our Team</a></li>
          </ol> */}
        </div>
      </header>

      <main>
        <section id="section_5ZtkF" className="section-base hc-cmp-section ourTeamSection  section-color   ">
          <div className="container ">
            <div className="row">
              <div id="column_HL8Z5" className="hc-cmp-column hc-column col-lg-12   hc-empty" style={{}}>
              </div>
              <div id="column_eNybu" className="hc-cmp-column hc-column col-lg-4  " style={{}}>
                <div id="kjlzt" className="hc-cmp-subtitle title     "><h2>LEADERSHIP</h2><p>Company's founders</p></div></div>
              <div id="column_0qX9u" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                <hr id="zOfd2" className="hc-cmp-space space-sm    " />
                <div className="row teamheadmng">
                  <div className="col-md-2">
                  <img src="../wp-content/uploads/2021/03/1.png" alt="" />
                  </div>
                  <div className="col-md-3">
                  <div className="caption">
                      <h2>Nilesh Rabadia</h2><div className="extra-field">FOUNDER &amp; CEO</div><div className="extra-qulification">Chartered Accountant</div> </div>
                  </div>
                  <div className="col-md-7">
                  <p className>More than 15 years of experience in financial services across collection, recovery and asset management
                        Led several transformation projects for banks/financial institutions in India
                        Ex Citi, Kotak, HDFC, Arcil
                      </p>
                  </div>
                </div>

                <div className="row teamheadmng teamheadmngLast">
                  <div className="col-md-2">
                  <img src="../wp-content/uploads/2021/03/2.png" alt="" />
                  </div>
                  <div className="col-md-3">
                  <div className="caption">
                      <h2>Vijit Barauh</h2><div className="extra-field">Lead and Senior Expert</div><div className="extra-qulification">MBA Finance</div> </div>
                  </div>
                  <div className="col-md-7">
                  <p className>22 years experience in banking and financial services,
                      Ex- Citi banker.
                      Implemented credit and collection system from scratch at 2 banks
                      Expert in
                      tele-calling led collection
                      </p>
                  </div>
                </div>

               
               <hr id="5eZvv" className="hc-cmp-space space    " />
                <div id="aZ7A2" className="hc-cmp-subtitle title     "><h2>Our team of experts</h2><p>TEAM MEMBERS</p></div><div id="rHzdb" className="hc-component hc-cmp-grid-list grid-list   " data-columns={3} data-columns-lg={3} data-columns-md={2} data-columns-sm={1} data-columns-xs={1}><div className="grid-box"><div className="grid-item"><div id="9tczh" className="cnt-box hc-cmp-niche-content-box-team cnt-box-team   boxed ">
                  <img src="../wp-content/uploads/2021/03/3.png" alt="" />
                  <div className="caption temcaptionnielsh">
                    <h2>
                      Nilesh Kanojia      </h2>
                    <span>
                      Head, Telecalling and Field Operations      </span>
                    <span className="icon-links">
                      <a target="_blank" href="#"><i className="icon-facebook" /></a> <a target="_blank" href="#"><i className="icon-twitter" /></a> <a target="_blank" href="#"><i className="icon-linkedin" /></a>      </span>
                    <p>
                      Collection &amp; recovery expert with more than 20 years experience in tele calling and field operations
                      Developed ground up ACS for 3 companies
                      Ex Citi, Standard Chartered
                    </p>
                  </div>
                </div>
                </div><div className="grid-item"><div id="O8lgL" className="cnt-box hc-cmp-niche-content-box-team cnt-box-team   boxed ">
                  <img src="../wp-content/uploads/2021/03/4.png" alt="" />
                  <div className="caption">
                    <h2>
                      Saif Kamaal      </h2>
                    <span>
                      Head Technology      </span>
                    <span className="icon-links">
                      <a target="_blank" href="#"><i className="icon-facebook" /></a> <a target="_blank" href="#"><i className="icon-twitter" /></a> <a target="_blank" href="#"><i className="icon-linkedin" /></a>      </span>
                    <p>
                      ~12 years of experience with system and product development
                      Ex- Oracle, Atos (Germany) and Emirates
                      Worked on project for Abu Dhabi Commercial Bank (process digitization, new product development etc.)
                      MCA and Bachelor of Computer sciences
                    </p>
                  </div>
                </div>
                  </div>
                  {/* <div className="grid-item"><div id="5BTYQ" className="cnt-box hc-cmp-niche-content-box-team cnt-box-team   boxed ">
                    <img src="../wp-content/uploads/2021/03/5-2.png" alt="" />
                    <div className="caption">
                      <h2>
                        Karan Paradkar      </h2>
                      <span>
                        Data Scientist      </span>
                      <span className="icon-links">
                        <a target="_blank" href="#"><i className="icon-facebook" /></a> <a target="_blank" href="#"><i className="icon-twitter" /></a> <a target="_blank" href="#"><i className="icon-linkedin" /></a>      </span>
                      <p>
                        Part of several engagements on data transformation and Big Data in BFSI space
                        Customer segmentation, collection model and data enrichment techniques
                        Deep expertise in identifying value from data and developing collection &amp; recovery programs targeted at extracting value
                      </p>
                    </div>
                  </div>
                  </div> */}
                  <div className="grid-item"><div id="ctYHs" className="cnt-box hc-cmp-niche-content-box-team cnt-box-team   boxed ">
                    <img src="../wp-content/uploads/2021/03/6-1.png" alt="" />
                    <div className="caption">
                      <h2>
                        Madan Chauhan      </h2>
                      <span>
                        Senior Expert      </span>
                      <span className="icon-links">
                        <a target="_blank" href="#"><i className="icon-facebook" /></a> <a target="_blank" href="#"><i className="icon-twitter" /></a> <a target="_blank" href="#"><i className="icon-linkedin" /></a>      </span>
                      <p>
                        More than 35 years banking experience in collection &amp; recovery
                        Bottom-up set-up of collection infra (tele-calling + field) at Bank of Baroda
                        Ex- GM, Bank of Baroda
                      </p>
                    </div>
                  </div>
                  </div></div></div><hr id="GTBBG" className="hc-cmp-space space-lg    " />
              </div>
              <div id="column_CDmU4" className="hc-cmp-column hc-column col-lg-4  " style={{}}>
                <div id="iRB5c" className="hc-cmp-icon-box icon-box icon-box-left     "><i className="im-air-balloon " /><div className="caption"><h3>THE BEST JOB</h3><p>All day immerse into the codes and amazing worlds.</p></div></div></div>
              <div id="column_ihXm3" className="hc-cmp-column hc-column col-lg-4  " style={{}}>
                <div id="G72pd" className="hc-cmp-icon-box icon-box icon-box-left     "><i className="im-beer " /><div className="caption"><h3>CAREER OPPORTUNITIES</h3><p>Grow with us is possible thanks to our levels structure.</p></div></div></div>
              <div id="column_19wwz" className="hc-cmp-column hc-column col-lg-4  " style={{}}>
                <div id="ZUEzw" className="hc-cmp-icon-box icon-box icon-box-left     "><i className="im-bee " /><div className="caption"><h3>MEET AMAZING PEOPLE</h3><p>We're the best team ever! Funny and friendly with each other.</p></div></div></div>
              <div id="column_RiA79" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                <hr id="hdKkx" className="hc-cmp-space space    " />
                <div id="bkJkh" className="cnt-box hc-cmp-niche-content-box-call cnt-call    ">
                  <div className="caption">
                    <h2>Join our team</h2><p>We are hiring at multiple functions (data analytics, operations and technology). </p>        <a className=" btn btn-xs btn-border " href="/contact-us-2">
                      Contact us      </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <FooterPart />
    </>

  )
}

export default OurTeam;