import React from 'react';
import FooterPart from '../FooterPart';
import HeadPart from '../HeadPart';

function TechSolution() {
    return (
        <>
        <HeadPart />
            <header id="header" className="hc-cmp-header hc-component header-imagePass" style={{ backgroundImage: 'url(../wp-content/uploads/2019/06/hd-wide-6-1920x500.jpg)' }}>
                <div className="container someheaderBanner">
                    <div className='policymngChanges'>
                        <h1>Technology Solution</h1>
                    </div>
                    {/* <h1 className='policymngChanges'>About Us</h1> */}
                    
                </div>
            </header>
            <main>
                <section id="section_5ZtkF" className="section-base hc-cmp-section  section-color   ">
                    <div className="container ">
                        <div className="row">
                            <div id="column_ZH04W" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                                <div id="3XB2A" className="hc-cmp-image   "><img src="../wp-content/uploads/2021/03/sol.jpg" alt="" /></div></div>
                            <div id="column_0KfFQ" className="hc-cmp-column hc-column col-lg-12   hc-empty" style={{}}>
                            </div>
                            <div id="column_WpkKc" className="hc-cmp-column hc-column col-lg-12  " style={{ textAlign: 'justify', marginTop:0 }}>
                                <div id="zx6q2" className="hc-cmp-subtitle title     "><h2>Technology Solution</h2><p /></div><p id="eajF4" className="hc-cmp-text-block    " style={{ marginTop: '-18px', textAlign: 'justify' }}>Acenna has developed best in class automated cloud based collection management system that is hosted on Amazon and it uses AWS APIs to cater the need of today's demanding and be at par with the industry in terms of technology. We also provide mobile app to our field agents, managers for effective field collection. </p><div id="column_KHf0K" className="hc-cmp-column hc-column col-lg-12  " style={{ marginTop: '25px' }}>
                                    <ul id="BzNpn" className="hc-cmp-icon-list icon-list     icon-circle  " style={{ marginTop: '0px' }}><li><p>Panel access to clients for 24X7 monitoring (disposition feedback, trend analysis, pin wise details etc.)</p></li><li><p>Integrated calling CRM with 360-degree view of customers.</p></li><li><p>Android base app for quality visits and field management.</p></li><li><p>100% data security (no customer details or loan details are shared with field agent or partner agency).</p></li></ul></div>
                            </div>
                            <div id="column_eWxXs" className="hc-cmp-column hc-column col-lg-6  " style={{ marginTop: '25px' }}>
                                <div id="ZWbha" style={{ marginTop: '25px' }} className="hc-cmp-image   "><img src="../wp-content/uploads/2021/03/dashboard-11.jpg" alt="" /></div></div>
                            <div id="column_jTKnS" className="hc-cmp-column hc-column col-lg-6  " style={{ marginTop: '25px' }}>
                                <div id="TRgOo" style={{ marginTop: '25px' }} className="hc-cmp-image   "><img src="../wp-content/uploads/2021/03/dashboard-12.jpg" alt="" /></div></div>
                            <div id="column_ffbGW" className="hc-cmp-column hc-column col-lg-4  " style={{ marginTop: '25px' }}>
                                <div id="4jEHi" className="hc-cmp-image   "><img src="../wp-content/uploads/2021/03/dashboard-13.jpg" alt="" /></div></div>
                            <div id="column_IlThe" className="hc-cmp-column hc-column col-lg-4  " style={{ marginTop: '25px' }}>
                                <div id="BJrla" className="hc-cmp-image   "><img src="../wp-content/uploads/2021/03/dashboard-14.jpg" alt="" /></div></div>
                            <div id="column_X890S" className="hc-cmp-column hc-column col-lg-4  " style={{ marginTop: '25px' }}>
                                <div id="ihBPZ" className="hc-cmp-image   "><img src="../wp-content/uploads/2021/03/dashboard-15.jpg" alt="" /></div></div>
                            <div id="column_IzOIa" className="hc-cmp-column hc-column col-lg-3  " style={{ marginTop: '25px' }}>
                                <div id="vZTXv" className="hc-cmp-image   "><img src="../wp-content/uploads/2021/03/11-1-1.jpg" alt="" /></div></div>
                            <div id="column_9xZDL" className="hc-cmp-column hc-column col-lg-3  " style={{ marginTop: '25px' }}>
                                <div id="mvXOB" className="hc-cmp-image   "><img src="../wp-content/uploads/2021/03/22.jpg" alt="" /></div></div>
                            <div id="column_AGx5H" className="hc-cmp-column hc-column col-lg-3  " style={{ marginTop: '25px' }}>
                                <div id="DsBhk" className="hc-cmp-image   "><img src="../wp-content/uploads/2021/03/33.jpg" alt="" /></div></div>
                            <div id="column_Sg7A9" className="hc-cmp-column hc-column col-lg-3  " style={{ marginTop: '25px' }}>
                                <div id="mampw" className="hc-cmp-image   "><img src="../wp-content/uploads/2021/03/44.jpg" alt="" /></div></div>
                        </div>
                    </div>
                </section>
            </main>
            <FooterPart />
        </>
    )
}

export default TechSolution;