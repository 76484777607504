import * as React from "react";
import './App.css';
import Home from './component/Pages/Home';
import About from './component/Pages/About';
import Analytics from './component/Pages/Analytics';
import { Route, BrowserRouter, Routes } from "react-router-dom";
// import '../src/component/media/css/CustomStyles.css';
// E:\Acenna\Acenna_Web\src\component\media\css\CustomStyles.css
import ContactUs from './component/Pages/ContactUs';
import Demo from './component/Pages/Demo';
import TechSolution from './component/Pages/TechSolution';
import OurTeam from './component/Pages/OurTeam';
import GrievanceRedressal from './component/Pages/GrievanceRedressal';
import PrivacyPolicy from './component/Pages/Privacy-policy';
import DataPolicy from './component/Pages/Data-policy';
import EmailSend from './component/EmailSend';
import Thank_you from './component/Thank_You';
import Page_Not_Found from './component/Pages/PageNotFound';
import Our_Partners from './component/Pages/Our_Partners';
// import ContactModal1 from "./component/ContactModal1";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from "react";
import Modal from "./component/Modal";
import { useEffect } from "react";
import { Custom_Data } from "./component/Pages/utilis";

function App() {
  // const [modalIsOpen, setModalIsOpen] = useState(true);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const setModalTimer = () => {
    const closeTime = new Date().getTime(); // Modal बंद करने का समय
    localStorage.setItem("modalCloseTime", closeTime); // इसे localStorage में सेव करें

    const timer = 10 * 60 * 60 * 1000; // 10 घंटे
    setTimeout(() => {
      const isFormSubmitted = localStorage.getItem("formSubmit");
      if (isFormSubmitted !== "true") {
        setModalIsOpen(true); // 10 घंटे बाद Modal खोलें (अगर फॉर्म सबमिट नहीं हुआ है)
      }
    }, timer);
  };

  useEffect(() => {
    const formSubmitValue = localStorage.getItem("formSubmit");
    const modalCloseTime = localStorage.getItem("modalCloseTime");

    if (formSubmitValue === "true") {
      setModalIsOpen(false); // अगर फॉर्म सबमिट हो चुका है, तो Modal न खोलें
      return;
    }

    if (modalCloseTime) {
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - parseInt(modalCloseTime, 10);

      const timer = 10 * 60 * 60 * 1000; // 10 घंटे
      // const timer = 2 * 60 * 1000; // 2 मिनट (मिलीसेकंड में)

      if (elapsedTime >= timer) {
        setModalIsOpen(true); // अगर 10 घंटे बीत चुके हैं, तो Modal खोलें
      } else {
        setTimeout(() => {
          setModalIsOpen(true); // बाकी समय के बाद Modal खोलें
        }, timer - elapsedTime);
      }
    } else {
      setModalIsOpen(true); // पहली बार, तुरंत खोलें
    }
  }, []);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const name = data.get("name");
    const mobileNumber = data.get("mobileNumber");
    const email = data.get("email");

    if (name && email && mobileNumber) {
      console.log("Form data submitted:", { name, mobileNumber, email });

      const formData = {
        feature: "ACENNA_WEB",
        featureType: "DEBT_COUNSELLING",
        name: name,
        email: email,
        mobileNumber: mobileNumber,
      };

      try {
        const response = await Custom_Data(formData); // आपका API कॉल
        console.log("Response:", response);

        localStorage.setItem("formSubmit", "true"); // फॉर्म सबमिट की स्थिति सेव करें
        setModalIsOpen(false); // Modal बंद करें
      } catch (error) {
        console.error("Error submitting data", error);
      }
    } else {
      alert("Please enter your Name, Email, and Mobile Number.");
    }
  };

  const handleModalClose = () => {
    setModalIsOpen(false); // Modal बंद करें
    setModalTimer(); // 10 घंटे का टाइमर सेट करें
  };

  return (
    <>
      {/* <ContactModal1
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
      /> */}


      <Modal show={modalIsOpen} setModalIsOpen={setModalIsOpen} onClose={() => setModalIsOpen(false)}>
        <div className="row">
          <div className="col-md-12 headerModal  d-flex justify-contant-end ">
            <div className="col-8">
              <h2>Register for free debt counselling</h2>
            </div>

            <div className="col-4">
              {/* <div className="modal" onClick={(e) => e.stopPropagation()}> */}
              <button className="close-button"
                onClick={handleModalClose}>
                &times;
              </button>
            </div>
          </div>
        </div>


        {/* </div> */}


        <form onSubmit={handleFormSubmit}>
          <div className="row">
            <div className="col-12 d-flex justify-contant-end modalContaint">

              <div className="col-md-6">
                <img src="wp-content/uploads/logo/debt-new.jpeg" alt="" />
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="name" className="groupLabel">Name</label>
                  <input type="text" id="name" name="name" className="form-control formGinput" />
                </div>

                <div className="form-group">
                  <label htmlFor="phone" className="groupLabel">Phone</label>
                  <input type="number" id="mobileNumber" name="mobileNumber" className="form-control formGinput" />
                </div>

                <div className="form-group">
                  <label htmlFor="email" className="groupLabel">Email</label>
                  <input type="email" id="email" name="email" className="form-control formGinput" />
                </div>

                <div className="d-flex justify-content-center">
                  {/* <button type="button" onClick={() => setModalIsOpen(false)} className="btn btn-secondary mr-2">Cancel</button> */}
                  <button type="submit" className="btn btn-primary submitBtn">Submit</button>
                </div>
              </div>

            </div>

          </div>
        </form>
      </Modal >



      {/* <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
      <HeadPart />
      <FooterPart /> */}

      < BrowserRouter >
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/analytics' element={<Analytics />} />
          <Route path='/techsoluiton' element={<TechSolution />} />
          <Route path='/ourTeam' element={<OurTeam />} />
          <Route path='/contact-us-2' element={<ContactUs />} />
          <Route path='/demo' element={<Demo />} />
          <Route path='/grievance-redressal-mechanism' element={<GrievanceRedressal />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/DataPolicy' element={<DataPolicy />} />
          <Route path='/EmailSend' element={<EmailSend />} />
          <Route path='/Thank_you' element={<Thank_you />} />
          <Route path='/Our_Partners' element={<Our_Partners />} />
          <Route path="*" element={<Page_Not_Found />} />
        </Routes>
      </BrowserRouter >
    </>

  );
}

export default App;
