import React from 'react';
import FooterPart from '../FooterPart';
import HeadPart from '../HeadPart';
function Analytics() {
  return (
    <>
    <HeadPart />
      <header id="header" className="hc-cmp-header hc-component header-imagePass" style={{ backgroundImage: 'url(../wp-content/uploads/2019/06/hd-wide-6-1920x500.jpg)' }}>
        <div className="container someheaderBanner">
          <div className='policymngChanges'>
            <h1>Analytics Driven Collection</h1>
          </div>
          {/* 
   <h1 className='policymngChanges'>About Us</h1>
   */}
          
        </div>
      </header>
      <main>
        <section id="section_5ZtkF" className="section-base hc-cmp-section analyticessectioncon  section-color   ">
          <div className="container ">
            <div className="row">
              {/* <div id="column_W0bL4" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                <ul id="xXDlH" className="hc-cmp-icon-list icon-list       " style={{ listStyle: 'none !important' }}>
                  <li>
                    <p><b><a style={{ textDecoration: 'none' }} className="lhover" href="#column_b278o">1.  Data is core to overall collection</a></b></p>
                  </li>
                  <li>
                    <p><b><a style={{ textDecoration: 'none' }} className="lhover" href="#column_pw3QH">2. Digital Legal Services</a></b></p>
                  </li>
                  <li>
                    <p><b><a style={{ textDecoration: 'none' }} className="lhover" href="#column_387JF">3. PAN India field presence with best-in-class tele calling</a></b></p>
                  </li>
                  <li>
                    <p><b><a style={{ textDecoration: 'none' }} className="lhover" href="#column_AbobK">4. Campaign Management</a></b></p>
                  </li>
                  <li>
                    <p><b><a style={{ textDecoration: 'none' }} className="lhover" href="#column_StgsP">5. Enhancing customer contactability through Skip Trace</a></b></p>
                  </li>
                </ul>
              </div> */}
              <div id="column_RmNzb" className="hc-cmp-column hc-column col-lg-12  " style={{ marginTop: '25px' }}>
                <ul id="ykdjV" className="hc-cmp-slider slider   arrows-inner-right " data-options="arrows:true">
                  <li>
                    <a id="1GydP" className="hc-cmp-media-box media-box media-box-reveal      scroll-to " href="#">
                      <img alt="" src="../wp-content/uploads/2021/03/acenna_background.jpg" />
                      <div className="caption">
                        <h2>Data is core to overall collection</h2>
                        <div className="extra-field">acenna</div>
                        <h3>April, 2021</h3>
                        <p>With the technological advancements, Data Science allows the use of sophisticated statistical models as well as predictive models. </p>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a id="CcANf" className="hc-cmp-media-box media-box media-box-reveal      scroll-to " href="#">
                      <img alt="" src="../wp-content/uploads/2021/03/6054a14cbedd0.jpg" />
                      <div className="caption">
                        <h2>Digital Legal Services</h2>
                        <div className="extra-field">acenna</div>
                        <h3>April, 2021</h3>
                        <p>Information cleaning and readiness is a crucial piece of the information mining measure. </p>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a id="SSiIl" className="hc-cmp-media-box media-box media-box-reveal      scroll-to " href="#">
                      <img alt="" src="../wp-content/uploads/2021/03/6054a2e2c4516.jpg" />
                      <div className="caption">
                        <h2>PAN India field presence with best-in-class tele calling</h2>
                        <div className="extra-field">acenna</div>
                        <h3>April, 2021</h3>
                        <p>Tele-calling operations is aided with best-in-class auto dialler, floor manager and seasoned callers.</p>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a id="8oGV0" className="hc-cmp-media-box media-box media-box-reveal      scroll-to " href="#">
                      <img alt="" src="../wp-content/uploads/2021/03/6054a3d64d760.jpg" />
                      <div className="caption">
                        <h2>Campaign Management</h2>
                        <div className="extra-field">acenna</div>
                        <h3>April, 2021</h3>
                        <p>For our clients, we offer end to end campaign management (designing of campaign, implementation, monitoring)</p>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a id="OGx7f" className="hc-cmp-media-box media-box media-box-reveal      scroll-to " href="#">
                      <img alt="" src="../wp-content/uploads/2021/03/6054a4b8c51bc-1.jpg" />
                      <div className="caption">
                        <h2>Enhancing customer contactability through Skip Trace</h2>
                        <div className="extra-field">acenna</div>
                        <h3>April, 2021</h3>
                        <p>We use over 500 legitimate data sources (direct or indirect ownership), bureau data,</p>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div id="column_b278o" name="column_b278o" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                <div id="3VaTr" className="hc-cmp-subtitle title     ">
                  <h2>1.  Data is core to overall collection</h2>
                  <p />
                </div>
                <p id="aUe8a" className="hc-cmp-text-block    " style={{ textAlign: 'justify', marginTop: '-18px' }}>With the technological advancements, Data Science allows the use of sophisticated statistical models as well as predictive models. The ability to analyse &amp; forecast transaction volumes is the key for improving product value for consumer/client. Data Science helps us to better classify payment records and thus tailor additional e-services to the client’s needs.</p>
              </div>
              <div id="column_7NcF5" className="hc-cmp-column hc-column col-lg-7  " style={{}}>
                <div id="EXq7X" className="hc-cmp-subtitle title     " style={{}}>
                  <h3>Information cleaning and readiness</h3>
                  <p />
                </div>
                <p id="bbVcq" className="hc-cmp-text-block    " style={{ textAlign: 'justify', marginTop: '-18px' }}>Information cleaning and readiness is a crucial piece of the information mining measure. Crude information should be scrubbed and arranged to be helpful in various insightful techniques. Information cleaning and arrangement incorporates various components of information demonstrating, change, information relocation, ETL, ELT, information coordination, and conglomeration. It's an essential advance for understanding the fundamental highlights and properties of information to decide its best use. The business estimation of information cleaning and readiness is undeniable. Without this initial step, information is either good for nothing to an association or temperamental because of its quality. These means are likewise important for information quality and legitimate information administration.</p>
              </div>
              <div id="column_WfOYs" className="hc-cmp-column hc-column col-lg-5  " style={{}}>
                <div id="hGhlt" style={{ marginTop: '30px' }} className="hc-cmp-image   "><img src="../wp-content/uploads/2021/03/tech-bisiness.jpg" alt="" />
                </div>
              </div>
              <div id="column_cDZLW" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                <div id="CjU3j" className="hc-cmp-subtitle title     ">
                  <h3>Tracking Patterns</h3>
                  <p />
                </div>
                <p id="XU761" className="hc-cmp-text-block    " style={{ textAlign: 'justify', marginTop: '-18px' }}>It includes recognizing and observing patterns or examples in information to make astute surmisings about business results. When an association recognizes a pattern in deals information, for instance, there's a reason for making a move to gain by that knowledge. On the off chance that it's resolved that a specific item is selling more than others for a specific segment, an association can utilize this information to make comparative items or administrations, or just better stock the first item for this segment.</p>
              </div>
              <div id="column_XplqG" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                <div id="TpHyX" className="hc-cmp-subtitle title     ">
                  <h3>Classification</h3>
                  <p />
                </div>
                <p id="4PUyb" className="hc-cmp-text-block    " style={{ textAlign: 'justify', marginTop: '-18px' }}>Arrangement information mining strategies include dissecting the different ascribes related with various kinds of information. When associations distinguish the fundamental attributes of these information types, associations can sort or order related information. Doing so is basic for recognizing, for instance, actually recognizable data associations might need to shield or redact from reports.</p>
              </div>
              <div id="column_ekoiF" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                <div id="OE8sf" className="hc-cmp-subtitle title     ">
                  <h3>Exception Identification</h3>
                  <p />
                </div>
                <p id="iNsHp" className="hc-cmp-text-block    " style={{ textAlign: 'justify', marginTop: '-18px' }}>Exception discovery decides any oddities in datasets. When associations discover abnormalities in their information, it gets more clear why these inconsistencies occur and get ready for any future events to best accomplish business targets.</p>
              </div>
              <div id="column_vZJJA" className="hc-cmp-column hc-column col-lg-7  " style={{}}>
                <div id="5wQuD" className="hc-cmp-subtitle title     ">
                  <h3>Clustering</h3>
                  <p />
                </div>
                <p id="pcfX0" className="hc-cmp-text-block    " style={{ textAlign: 'justify', marginTop: '-18px' }}>It is an investigation strategy that depends on visual ways to deal with getting information. Grouping systems use designs to show where the dispersion of information is comparable to various kinds of measurements. Bunching strategies additionally utilize various tones to show the circulation of information. Diagram approaches are ideal for utilizing bunch examination. With charts and clustering specifically, clients can outwardly perceive how information is circulated to distinguish patterns that are pertinent to their business destinations.</p>
              </div>
              <div id="column_kHvGw" className="hc-cmp-column hc-column col-lg-5  " style={{}}>
                <div id="jpndB" style={{ marginTop: '30px' }} className="hc-cmp-image   "><img src="../wp-content/uploads/2021/03/clustering.png" alt="" />
                </div>
              </div>
              <div id="column_IuSaS" className="hc-cmp-column hc-column col-lg-7  " style={{}}>
                <div id="SRGRz" className="hc-cmp-subtitle title     ">
                  <h4>Progressive/Agglomerative Clustering </h4>
                  <p />
                </div>
                <p id="YG94i" className="hc-cmp-text-block    " style={{ textAlign: 'justify', marginTop: '-18px' }}>Calculations start with each point in its own bunch. Bunches are joined dependent on their closeness, utilizing one of numerous potential meanings of close. Mix stops when further blend prompts groups that are unwanted for one of a few reasons. For instance, we may stop when we have a foreordained number of groups, or we may utilize a proportion of conservativeness for bunches, and decline to build a group by consolidating 2 more modest bunches if the subsequent bunch has focuses that are fanned out over too enormous an area</p>
              </div>
              <div id="column_OfK2u" className="hc-cmp-column hc-column col-lg-5  " style={{}}>
                <div id="JD2za" style={{ marginTop: '30px' }} className="hc-cmp-image   "><img src="../wp-content/uploads/2021/03/progressivelive.png" alt="" />
                </div>
              </div>
              <div id="column_yhwyq" className="hc-cmp-column hc-column col-lg-7  " style={{}}>
                <div id="xf2E4" className="hc-cmp-subtitle title     ">
                  <h4>K-Means Clustering</h4>
                  <p />
                </div>
                <p id="8HSHV" className="hc-cmp-text-block    " style={{ textAlign: 'justify', marginTop: '-18px' }}>Assuming an Euclidean space, there are actually k groups for some known k. In the wake of picking k introductory bunch centroids, the focuses are viewed as each in turn and relegated to the nearest centroid. The centroid of a group can relocate during point task, and a discretionary last advance is to reassign every one of the focuses while holding the centroids fixed at their last qualities got during the principal pass.</p>
              </div>
              <div id="column_6PCaI" className="hc-cmp-column hc-column col-lg-5  " style={{}}>
                <div id="DfOWW" style={{ marginTop: '30px' }} className="hc-cmp-image   "><img src="../wp-content/uploads/2021/03/segment-1.png" alt="" />
                </div>
              </div>
              <div id="column_pHNaW" className="hc-cmp-column hc-column col-lg-7  " style={{}}>
                <div id="D6xB1" className="hc-cmp-subtitle title     ">
                  <h3>Regression</h3>
                  <p />
                </div>
                <p id="1DGsy" className="hc-cmp-text-block    " style={{ textAlign: 'justify', marginTop: '-18px' }}>Regression strategies are valuable for recognizing the idea of the connection between factors in a dataset. Those connections could be causal in certain cases, or basically associate in others. It is a direct white box procedure that plainly uncovers how factors are connected. Its procedures are utilized in parts of estimating and information displaying.</p>
              </div>
              <div id="column_5yM1j" className="hc-cmp-column hc-column col-lg-5  " style={{}}>
                <div id="TZbKI" style={{ marginTop: '30px' }} className="hc-cmp-image   "><img src="../wp-content/uploads/2021/03/regressionlive.png" alt="" />
                </div>
              </div>
              <div id="column_X3yoD" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                <div id="etwCf" className="hc-cmp-subtitle title     ">
                  <h3>Prediction</h3>
                  <p />
                </div>
                <p id="eFdBM" className="hc-cmp-text-block    " style={{ textAlign: 'justify', marginTop: '-18px' }}>Expectation is an amazing part of information mining that addresses one of four parts of examination. Prescient investigations use designs found in current or verifiable information to broaden them into what's to come. Subsequently, it gives associations knowledge into what patterns will occur next in their information. There are a few unique ways to deal with utilizing prescient investigation. A portion of the further developed include parts of AI and <br />computerized reasoning. Be that as it may, prescient investigation doesn't really rely upon these procedures — it can likewise be encouraged with more direct calculations.</p>
              </div>
              <div id="column_CHf0f" className="hc-cmp-column hc-column col-lg-7  " style={{}}>
                <div id="sbknA" className="hc-cmp-subtitle title     ">
                  <h3>Decision Trees </h3>
                  <p />
                </div>
                <p id="n10Q8" className="hc-cmp-text-block    " style={{ textAlign: 'justify', marginTop: '-18px' }}>These are a particular kind of prescient model that allows associations successfully to mine information. Actually, it’s important for AI, however it is all the more prevalently known as a white box AI strategy in view of its amazingly direct nature. A decision tree empowers clients to obviously see what the information inputs mean for the yields. At the point when different decision tree models are joined they make prescient investigation models known as an arbitrary woodland. Much of the time, be that as it may, this essential type of gathering displaying is more precise than utilizing decision trees all alone.</p>
              </div>
              <div id="column_inCJ6" className="hc-cmp-column hc-column col-lg-5  " style={{}}>
                <div id="w7u4S" style={{ marginTop: '30px' }} className="hc-cmp-image   "><img src="../wp-content/uploads/2021/03/decision-trees.png" alt="" />
                </div>
              </div>
              <div id="column_pnF8W" className="hc-cmp-column hc-column col-lg-7  " style={{}}>
                <div id="lssXq" className="hc-cmp-subtitle title     ">
                  <h3>Feature Detection/Selection</h3>
                  <p />
                </div>
                <p id="50LFQ" className="hc-cmp-text-block    " style={{ textAlign: 'justify', marginTop: '-18px' }}>Feature extraction is a piece of the dimensionality decrease measure, in which, an underlying arrangement of the crude information is partitioned and diminished to more sensible gatherings. So when you need to deal with it will be simpler. The main attribute of these enormous informational indexes is that they have countless factors. These factors require a great deal of figuring assets to deal with them. So Feature extraction assists with getting the best component from those huge informational collections by select and join factors into highlights, accordingly, adequately diminishing the measure of information. These highlights are not difficult to measure, yet ready </p>
              </div>
              <div id="column_hq4rA" className="hc-cmp-column hc-column col-lg-5  " style={{}}>
                <div id="oj8gt" style={{ marginTop: '30px' }} className="hc-cmp-image   "><img src="../wp-content/uploads/2021/03/feater.png" alt="" />
                </div>
              </div>
              <div id="column_lyRwh" className="hc-cmp-column hc-column col-lg-7  " style={{}}>
                <div id="6Q9dA" className="hc-cmp-subtitle title     ">
                  <h3>Visualization</h3>
                  <p />
                </div>
                <p id="Yybul" className="hc-cmp-text-block    " style={{ textAlign: 'justify', marginTop: '-18px' }}>Information representations are another significant component of information mining. They award clients knowledge into information dependent on tangible discernments that individuals can see. The present information representations are dynamic, helpful for streaming information continuously, and described by various tones. Dashboards are an incredible method to utilize information representations to reveal information mining bits of knowledge.  Associations can put together dashboards with respect to various measurements and use representations to outwardly feature designs in information, rather than essentially utilizing mathematical yields of factual models.</p>
              </div>
              <div id="column_7OX0u" className="hc-cmp-column hc-column col-lg-5  " style={{}}>
                <div id="EUYzt" style={{ marginTop: '30px' }} className="hc-cmp-image   "><img src="../wp-content/uploads/2021/03/visualization.png" alt="" />
                </div>
              </div>
              <div id="column_6Db4L" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                <p id="9p3on" className="hc-cmp-text-block    " style={{ textAlign: 'justify' }}><b>Long Term Processing</b><br />Long term memory preparing alludes to the capacity to investigate information throughout broadened timeframes. The noteworthy information put away in information distribution centers is valuable for this reason. At the point when an association can perform investigation on an all-encompassing timeframe, it's ready to recognize designs that in any case may be too inconspicuous to even consider distinguishing. For instance, by dissecting steady loss over a time of quite a long while, an association may discover unobtrusive hints that could prompt lessening stir in money.</p>
              </div>
              <div id="column_rnCgv" className="hc-cmp-column hc-column col-lg-7  " style={{}}>
                <div id="yj4TQ" className="hc-cmp-subtitle title     ">
                  <h3>Path Optimization using Reinforcement Learning.</h3>
                  <p />
                </div>
                <p id="aNQ7U" className="hc-cmp-text-block    " style={{ textAlign: 'justify', marginTop: '-18px' }}>The Travelling salesman issue is an exemplary issue in combinatorial improvement. This issue is to locate the briefest way that a sales representative should take to navigate through elite of urban/rural communities and get back to the original location.<br /><b>Simulated Annealing</b><br />is a powerful and versatile optimization technique. It's useful for locating global optima when there are a lot of local optima. Instead of using the capacity of a product, simulated annealing uses the objective function of an optimization problem. </p>
              </div>
              <div id="column_XdMTJ" className="hc-cmp-column hc-column col-lg-5  " style={{}}>
                <div id="5f5Zm" style={{ marginTop: '30px' }} className="hc-cmp-image   "><img src="../wp-content/uploads/2021/03/path-optimiz.png" alt="" />
                </div>
              </div>
              <div id="column_dlqKo" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                <p id="vCPMZ" className="hc-cmp-text-block    " style={{ textAlign: 'justify' }}> is a powerful and versatile optimization technique. It's useful for locating global optima when there are a lot of local optima. Instead of using the capacity of a product, simulated annealing uses the objective function of an optimization problem. Implementing all these strategies has resulted in time and resource savings because we have a better understanding of our target market.</p>
              </div>
              <div id="column_5XRU5" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                <div id="7ZLoN" style={{ width: '100% !important', marginTop: '30px' }} className="hc-cmp-image   "><img src="../wp-content/uploads/2021/03/tech3.jpg" alt="" />
                </div>
              </div>
              <div id="column_pw3QH" name="column_pw3QH" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                <div id="kXUgx" className="hc-cmp-subtitle title     ">
                  <h2>2. Digital Legal Services</h2>
                  <p />
                </div>
                <p id="bS0yQ" className="hc-cmp-text-block    " style={{ textAlign: 'justify', marginTop: '-18px' }}>We have observed that initiating a legal action against defaulted customers is not only cumbersome but has many elements which are unknown to fintech’s and finance companies. Disruptive innovations provide an opportunity to lead the way in enabling more reliable, effective, open, and customer-centric legal services through digital legal solutions.  With our Digital approach towards legal process, we have integrated operations, legal and monitoring through our technology stack.<br />We have developed integrated digital legal services which allows our clients to initiate, monitor legal cases from our tech solution.</p>
              </div>
              <div id="column_IxRKA" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                <p id="InBoQ" className="hc-cmp-text-block    " style={{}}><b>Some of the key features are as follows:</b></p>
              </div>
              <div id="column_kpQgq" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                <ul id="wukyI" className="hc-cmp-icon-list icon-list     icon-circle  " style={{ marginRight: '-5px' }}>
                  <li>
                    <p />
                    <p>Provide end to end digital legal services (local lawyer identification, notice finalization, delivery of notices, court case hearing, response and tracking)</p>
                    <p />
                  </li>
                  <li>
                    <p>Multiple options to send legal notices(Post, in-person delivery, email, WhatsApp, SMS with link)</p>
                  </li>
                  <li>
                    <p>Clients can choose from empanelled lawyers in 30 locations PAN India (specific to client, new locations can be added immediately) </p>
                  </li>
                  <li>
                    <p>Legal panel integrated with our collection &amp; recovery system for end-to-end resolution, allows comprehensive overview of customer</p>
                  </li>
                  <li>
                    <p>Simple to use technology (upload features, tracking of courier, reminders on court dates over email etc.)</p>
                  </li>
                </ul>
              </div>
              <div id="column_m5OCZ" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                <div id="aaQfL" style={{ marginTop: '30px' }} className="hc-cmp-image   "><img src="../wp-content/uploads/2021/03/telecalling.jpg" alt="" />
                </div>
              </div>
              <div id="column_387JF" name="column_387JF" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                <div id="mBhhj" className="hc-cmp-subtitle title     ">
                  <h2>3. PAN India field presence with best-in-class tele calling</h2>
                  <p />
                </div>
              </div>
              <div className='panIndiaImage'>
                <img src='../wp-content/uploads/2019/06/new_map.jpg' alt='mapImage' />
              </div>
              <div id="column_KzWJJ" className="hc-cmp-column hc-column col-lg-8  " style={{}}>
                <div id="JleYv" className="hc-cmp-subtitle title     ">
                  <h3>Tele calling operations</h3>
                  <p />
                </div>
                <p id="mCkQe" className="hc-cmp-text-block    " style={{ textAlign: 'justify', marginTop: '-18px' }}>Tele-calling operations is aided with best-in-class auto dialler, floor manager and seasoned callers. System Based Calling along with continuous hiring of multi lingual callers in various languages such as English, Hindi, Marathi, Kannada, Tamil, Telugu, Malayalam.<br />Key elements of our tele calling operations are as follows:<br />
                  <ul>
                    <li>Customer escalation management</li>
                    <li>Collection call auditing</li>
                    <li>Call grading and daily feedback to agents</li>
                    <li>Categorization of calls by audit team</li>
                  </ul>
                </p>
              </div>
              <div id="column_vlr2r" className="hc-cmp-column hc-column col-lg-4  " style={{}}>
                <div id="1Tbih" style={{ marginTop: '30px' }} className="hc-cmp-image   "><img src="../wp-content/uploads/2021/03/teleoperating.png" alt="" />
                </div>
              </div>
              <div id="column_AbobK" name="column_AbobK" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                <div id="bFZdW" className="hc-cmp-subtitle title     ">
                  <h2>4. Campaign Management</h2>
                  <p />
                </div>
                <p id="KIFP2" className="hc-cmp-text-block    " style={{ marginTop: '-18px' }}>For our clients, we offer end to end campaign management (designing of campaign, implementation, monitoring)</p>
              </div>
              <div id="column_3EKRB" className="hc-cmp-column hc-column col-lg-12  " style={{ marginTop: '25px' }}>
                <ul id="Wn1Pe" className="hc-cmp-text-list text-list text-list-base    ">
                  <li>
                    <h3>Data management</h3>
                    <p>Keeping track of all collection dispositions, demographic details, repayment behavior.</p>
                  </li>
                  <li>
                    <h3>Targeting customers Through Various Modes of Communication</h3>
                    <p>By applying some machine learning techniques classify the group of customers and send legal notices accordingly through SMS’s, IVR’s and WhatsApp</p>
                  </li>
                  <li>
                    <h3>Personalized legal notices conveniently drafted and constantly monitored</h3>
                    <p>Personalization encourages you to have a relevant incentive or notification depending on the sort of order your customer has been looking at, increasing the open rate. </p>
                  </li>
                  <li>
                    <h3>Observe and enhance</h3>
                    <p>Analyze the customer pattern regarding the SMS’s sent, calculate the open rate and duration of engagement through IVR’s. This will further guide us to increase the recovery rate.</p>
                  </li>
                </ul>
              </div>
              <div id="column_StgsP" name="column_StgsP" className="hc-cmp-column hc-column col-lg-12  " style={{ marginTop: '17px' }}>
                <div id="OaWx2" className="hc-cmp-subtitle title     ">
                  <h2>5. Enhancing customer contactability through Skip Trace</h2>
                  <p />
                </div>
              </div>
              <div id="column_Lmpay" className="hc-cmp-column hc-column col-lg-7  " style={{}}>
                <p id="esar1" className="hc-cmp-text-block    " style={{ textAlign: 'justify', marginTop: '18px' }}>We use over 500 legitimate data sources (direct or indirect ownership), bureau data, social media, voter ID details, alternate details as well as our proprietary predictive artificial intelligence for enhancing customer engagement. In the present associated world, each customer is a fragment. We at Acenna uses our proprietary module to engage with customers to enhance collection and recovery efficiency. <br />Customer engagement is established through multiple sources in innovative manner to allow customer understand their obligations and its implications on future financial needs. </p>
              </div>
              <div id="column_G6Ky7" className="hc-cmp-column hc-column col-lg-5  " style={{}}>
                <div id="5T0CG" style={{ marginTop: '27px' }} className="hc-cmp-image   "><img src="../wp-content/uploads/2021/03/customer.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <FooterPart />
    </>
  )
}
export default Analytics;