import React from 'react';
import FooterPart from '../FooterPart';
import HeadPart from '../HeadPart';

function DataPolicy() {
    return (
        <>
            <HeadPart />
            <div>
                <header id="header" className="hc-cmp-header hc-component header-imagePass" style={{ backgroundImage: 'url(../wp-content/uploads/2019/06/hd-wide-1-1920x500.jpg)' }}>
                    <div className="container someheaderBanner">
                        <h1 className='policymngChanges'>Data Policy</h1>
                        {/* <ol className="breadcrumb ">
                            <li><a href="/">Home</a></li>
                            <li className="active"><a href="#">Data Policy</a></li>
                        </ol> */}
                    </div>
                </header>
                <main>
                    <section id="section_5ZtkF" className="section-base hc-cmp-section    ">
                        <div className="container ">
                            <div className="row">
                                <div id="column_QmN4L" className="hc-cmp-column hc-column col-lg-12  " style={{ marginTop: '-55px', marginBottom: '18px' }}>
                                    <div id="FmrEm" className="hc-cmp-subtitle title     ">
                                        <h2>Data Deletion Right</h2>
                                        <p />
                                    </div>
                                </div>
                                <div id="column_NJvAz" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                                    <p id="ZB6zL" className="hc-cmp-text-block    " style={{ textAlign: 'justify' }}>In case a user contacts us directly or via the in-app <b>Bug Report</b> option, we will collect their email for the sole purpose of communication and resolving their issue. However, if the user wants to exercise their Data Deletion Right, they can request us to delete their email and associated data from our records by contacting us through the same email address. We will respond promptly to the user's request and take the necessary actions to delete their data from our records.<br /></p>
                                </div>
                                <div id="column_PyWvB" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                                    <div id="nkLfR" className="hc-cmp-subtitle title     ">
                                        <h2>Instructions for Requesting Data Deletion</h2>
                                        <p />
                                    </div>
                                    <p id="EVqfF" className="hc-cmp-text-block    " style={{ textAlign: 'justify', marginTop: '0px' }}>To request data deletion, users may send an email to <span className='linkacennaPro'>info@acenna.in</span> from the email address that is valid or registered with the app data. The subject line of the email should read <b>'Acenna Data Deletion Request'</b> and the email should <b>include all necessary details.</b></p>

                                </div>

                                <div id="column_PyWvB" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                                    <div id="nkLfR" className="hc-cmp-subtitle title     ">
                                        <h2>Read Our Privacy Policy to Understand Use of Data</h2>
                                        <p />
                                    </div>
                                    <p id="EVqfF" className="hc-cmp-text-block    " style={{ textAlign: 'justify', marginTop: '0px' }}>We take our users' privacy seriously and are committed to protecting their personal information. To better understand how your data is used by us or our app, we encourage you to read our <a className='linkacennaPro' href='/privacy-policy'>Privacy Policy</a>. Our policy provides a detailed explanation of the types of data we collect, how we use it, and who we share it with. We also outline the measures we take to ensure the security and confidentiality of your information.</p>
                                    <p>By familiarizing yourself with our Privacy Policy, you can be assured that your personal information is being handled in a responsible and transparent manner. If you have any questions or concerns about our policy or how your data is being used, please do not hesitate to contact us. We value your trust and appreciate the opportunity to serve you.</p>
                                </div>

                            </div>
                        </div>
                    </section>
                </main>
            </div>
            <FooterPart />
        </>


    )
}

export default DataPolicy;