import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import FooterPart from '../FooterPart';
import HeadPart from '../HeadPart';
import ContactModal1 from '../../ContactModal1';
// let counter = 0;
function Home(e) {
    // e.preventDefault();

    return (
        <>
            <HeadPart />
            <div>
                <div>
                    <main>
                        <section id="section_5ZtkF" className="section-video hc-cmp-section  light  section-home-slider ">
                            <video autoPlay playsInline loop muted poster="wp-content/uploads/2019/06/video-poster-1917x1080.jpg">
                                <source src="wp-content/uploads/2019/06/video.mp4" type="video/mp4" />
                            </video>
                            <div className="container mngsliderimagres">
                                <div className="row">
                                    <div id="column_f7uZR" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                                        <hr id="RXfox" className="hc-cmp-space space    " />
                                    </div>
                                    <div id="column_wX3cj" className="hc-cmp-column hc-column col-lg-7 no-margin  " style={{}} data-anima="fade-right" data-time={2000}>
                                        <ul id="hqukk" className="hc-cmp-slider slider    " data-options="arrows:true,autoplay:3500,nav:true">
                                            <li>
                                                <div id="column_ocFdk" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                                                    <h1 id="njLMy" className="hc-cmp-title-tag    " style={{}}>The Best Way To Manage
                                                        Collections</h1><a id="k0CW8" className="hc-cmp-image-box img-box     scroll-to " href="#"><img src="wp-content/uploads/2019/06/image-8.jpg" alt="" data-width={800} data-height={500} /></a>
                                                </div>
                                            </li>
                                            <li>
                                                <div id="column_UwPYc" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                                                    <h1 id="3I4ru" className="hc-cmp-title-tag    " style={{}}>Analytics Driven Campaigning
                                                    </h1><a id="2Gqki" className="hc-cmp-image-box img-box     scroll-to " href="#"><img src="wp-content/uploads/2019/06/image-3.jpg" alt="" data-width={800} data-height={500} /></a>
                                                </div>
                                            </li>
                                            <li>
                                                <div id="column_W4K86" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                                                    <h1 id="GCrZY" className="hc-cmp-title-tag    " style={{}}>We use the state of art
                                                        Technology</h1><a id="oeuiN" className="hc-cmp-image-box img-box     scroll-to " href="#"><img src="wp-content/uploads/2019/06/image-6.jpg" alt="" data-width={800} data-height={500} /></a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div id="column_MOwsq" className="hc-cmp-column hc-column col-lg-5  " style={{}}>
                                        <div id="column_ErNFM" className="hc-cmp-column hc-column col-lg-4 col-sm-12  " style={{}} data-anima="fade-right" data-time={2000}>
                                            <div id="P59Mp" className="hc-component hc-cmp-grid-list grid-list   " data-columns={1} data-columns-lg={1} data-columns-md={3} data-columns-sm={3} data-columns-xs={1}>
                                                <div className="grid-box mobcityitembox" style={{ gridGap: '18px' }}>
                                                    <div className="grid-item">
                                                        <div id="5j6LO" className="hc-cmp-counter counter counter-icon counter-vertical    ">
                                                            <div>
                                                                <h3>CITIES</h3>
                                                                <div className="value">
                                                                    <span className="text-md" data-to={47}>0</span>
                                                                    <span className>+</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="grid-item">
                                                        <div id="8Ex0y" className="hc-cmp-counter counter counter-icon counter-vertical    ">
                                                            <div>
                                                                <h3>PINS</h3>
                                                                <div className="value">
                                                                    <span className="text-md" data-to={6000}>0</span>
                                                                    <span className>+</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="grid-item">
                                                        <div id="l7xRP" className="hc-cmp-counter counter counter-icon counter-vertical    ">
                                                            <div>
                                                                <h3>STAFF</h3>
                                                                <div className="value">
                                                                    <span className="text-md" data-to={80}>0</span>
                                                                    <span className>+</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="column_5pbHO" className="hc-cmp-column hc-column col-lg-8  " style={{}} data-anima="fade-right" data-time={3000}>
                                            <h2 id="8Rqxt" className="hc-cmp-title-tag    " style={{}}><span>Debt</span> Resolution</h2>
                                            <p id="01ffq" className="hc-cmp-text-block    " style={{ textAlign: 'justify' }}>Acenna is Mumbai
                                                based financial technology firm focused on debt resolution.</p>
                                            <NavLink to="/demo" className="hc-cmp-button     btn btn-xs" style={{ fontSize: '13px' }}>Book Demo</NavLink>
                                            {/* <a id="4bbXv" className="hc-cmp-button     btn btn-xs" href="/bookdemo" style={{ fontSize: '13px' }}>BOOK DEMO</a> */}
                                            <a id="ueuOZ" className="hc-cmp-button     btn btn-border btn-xs " href="https://www.acennacollect.in/auth/login" style={{ fontSize: '13px' }} target="_blank">PANEL LOGIN</a>
                                        </div>
                                    </div>
                                    <div id="column_laQUI" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                                        <hr id="vAJYm" className="hc-cmp-space space    " />
                                        <hr id="Xv4I5" className="hc-cmp-space space    " style={{ height: '50px' }} />
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section id="section_FGfFn" className="section-base hc-cmp-section  section-color no-padding no-padding-y  section-overflow-top ">
                            <div className="container mngsliderimagres">
                                <div className="row homepageFirstBox">
                                    <div id="column_ogdkU" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                                        <div id="OVqhE" className="hc-component hc-cmp-grid-list grid-list   " data-columns={3} data-columns-lg={3} data-columns-md={1} data-columns-sm={1} data-columns-xs={1}>
                                            <div className="grid-box mngtopboxgraid">
                                                <div className="grid-item">
                                                    <div id="klCaA" className="cnt-box-info    cnt-box hc-cmp-content-box  boxed ">
                                                        <a>
                                                            <img src="wp-content/uploads/2019/06/image-3.jpg" alt="" />
                                                        </a>
                                                        <div className="caption">
                                                            <h2>Data Analytics</h2>
                                                            <div className="cnt-info">
                                                            </div>
                                                            <p className>
                                                            </p>
                                                            <br />
                                                            <p className="ppjustify">We employ strategies across customer segmentation,
                                                                clustering and repayment behaviour to maximise collection efficiency and
                                                                customer engagement.</p>
                                                            <h4 className="displynone">.</h4>
                                                            <p />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="grid-item">
                                                    <div id="tgo4j" className="cnt-box-info    cnt-box hc-cmp-content-box  boxed " >
                                                        <a>
                                                            <img src="wp-content/uploads/2019/06/image-4.jpg" alt="" />
                                                        </a>
                                                        <div className="caption">
                                                            <h2>Technology Enabled Operations</h2>
                                                            <div className="cnt-info">
                                                            </div>
                                                            <p className>
                                                            </p><p className="pjustify">We operate with best-in-class collection management
                                                                system, proprietary skip module and mobile app for quality visits and
                                                                customer engagement.</p>
                                                            <br />
                                                            <p />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="grid-item">
                                                    <div id="nSo4S" className="cnt-box-info    cnt-box hc-cmp-content-box  boxed " >
                                                        <a>
                                                            <img src="wp-content/uploads/2019/06/image-7.jpg" alt="" />
                                                        </a>
                                                        <div className="caption">
                                                            <h2>Campaigns &amp; Digital Collections</h2>
                                                            <div className="cnt-info">
                                                            </div>
                                                            <p className>
                                                            </p><p className="pjustify">Campaigns are core to our digital collection. With
                                                                deploy multiple strategies for customer engagement and digital payment.
                                                            </p>
                                                            <h4 className="displynone">.</h4>
                                                            <p />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section id="section_XJF03" className="section-image hc-cmp-section  light    ken-burn-in" data-parallax="scroll" data-natural-height={1080} data-natural-width={1920} data-position data-image-src="wp-content/uploads/2019/06/hd-2.jpg">
                            <div className="container mngsliderimagres">
                                <div className="row">
                                    <div id="column_XFOSI" className="hc-cmp-column hc-column col-lg-12  " style={{}} data-anima="fade-bottom" data-time={2000}>
                                        <div id="QE1Aa" className="hc-cmp-subtitle title     ">
                                            <h2>Who is Acenna?</h2>
                                            <p>ABOUT US</p>
                                        </div>
                                        <p id="GMPAY" className="hc-cmp-text-block    " style={{ textAlign: 'justify' }}>Acenna is a Mumbai
                                            based financial technology firm focused on resolving collection and recovery for fintech,
                                            banks and NBFCs. The firm was founded by seasoned bankers from Citi, Standard, HDFC and
                                            Kotak. The promoters have more than 100 years of cumulative experience in finance and
                                            banking across India, Middle East and southeast
                                            Asia.<br /><br />---------------------<br /><b>Nilesh Rabadia</b><br /><span>Founder &amp;
                                                CEO</span></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section id="section_ij3TC" className="section-base hc-cmp-section  section-color   ">
                            <div className="container mngsliderimagres">
                                <div className="row">
                                    <div id="column_Tw3sH" className="hc-cmp-column hc-column col-lg-12  " style={{}} data-anima="fade-bottom" data-time={2000}>
                                        <div id="dMEkt" className="hc-cmp-subtitle title     ">
                                            <h2>What makes us different</h2>
                                            <p>Acenna USP</p>
                                        </div>
                                        <hr id="vl29r" className="hc-cmp-space space-xs    " />
                                        <div id="NiOBZ" className="hc-component hc-cmp-tab tab-box   tab-top-right  " data-tab-anima="fade-bottom">
                                            <ul className="tab-nav ">
                                                <li className="active"><a href="#">SOFTWARE</a></li>
                                                <li><a href="#">FINTECH</a></li>
                                            </ul>
                                            <div className="panel active homePageBox">
                                                <div id="dk6fY" className="hc-component hc-cmp-grid-list grid-list   " data-columns={3} data-columns-lg={2} data-columns-md={1} data-columns-sm={1} data-columns-xs={1}>
                                                    <div className="grid-box">
                                                        <div className="grid-item">
                                                            <div id="HhwFK" className="cnt-box-top-icon    cnt-box hc-cmp-content-box  boxed scroll-to " style={{ textAlign: 'justify' }} data-href="#">
                                                                <i className=" im-business-mens " />
                                                                <div className="caption">
                                                                    <h2>Seasoned Team</h2>
                                                                    <p className>
                                                                    </p><li>Team from Citi Bank, HDFC, Standard Chartered, Amex with more
                                                                        than 100 years of cumulative experience</li>
                                                                    <li>Mix of field veteran, tele calling, data analytics </li>
                                                                    <br />
                                                                    <p className="brdot">.</p>
                                                                    <p />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="grid-item">
                                                            <div id="OYC8v" className="cnt-box-top-icon    cnt-box hc-cmp-content-box  boxed scroll-to " style={{ textAlign: 'justify' }} data-href="#">
                                                                <i className=" im-bar-chart " />
                                                                <div className="caption">
                                                                    <h2>High Recovery Rate </h2>
                                                                    <p className>
                                                                    </p><li>More than 30% increased performance across NPA and late
                                                                        buckets</li>
                                                                    <li>Continuous focus on customer engagement for digital
                                                                        and cashless payments (from pre-delinquency to higher
                                                                        buckets)</li>
                                                                    <p />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="grid-item">
                                                            <div id="M3Ixb" className="cnt-box-top-icon    cnt-box hc-cmp-content-box  boxed scroll-to " style={{ textAlign: 'justify' }} data-href="#">
                                                                <i className=" im-letter-open " />
                                                                <div className="caption">
                                                                    <h2>Digital Legal Services</h2>
                                                                    <p className>
                                                                    </p><li>Multiple options to send legal notices</li>
                                                                    <li>Clients can choose from empaneled lawyers in 30 locations PAN India</li>
                                                                    <p />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="QNmlY" className="hc-component hc-cmp-grid-list grid-list   " data-columns={3} data-columns-lg={2} data-columns-md={1} data-columns-sm={1} data-columns-xs={1} style={{ marginTop: '30px' }}>
                                                    <div className="grid-box">
                                                        <div className="grid-item">
                                                            <div id="NAG9B" className="cnt-box-top-icon    cnt-box hc-cmp-content-box  boxed scroll-to " style={{ textAlign: 'justify' }} data-href="#">
                                                                <i className=" im-money-bag " />
                                                                <div className="caption">
                                                                    <h2> Reduced Cost</h2>
                                                                    <p className>
                                                                    </p><li>PAN India presence (field agents and supervisors)</li>
                                                                    <li>Well defined campaigns to target customers for digital
                                                                        repayments</li>
                                                                    <p className="brdot">.</p>
                                                                    <p />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="grid-item">
                                                            <div id="1pC63" className="cnt-box-top-icon    cnt-box hc-cmp-content-box  boxed scroll-to " style={{ textAlign: 'justify' }} data-href="#">
                                                                <i className=" im-cloud-video " />
                                                                <div className="caption">
                                                                    <h2>Technology Stack</h2>
                                                                    <p className>
                                                                    </p><li>Best in class collection, cloud-based recovery solution </li>
                                                                    <li>Field and calling aided with android app and real time information</li>
                                                                    <p className="brdot">.</p>
                                                                    <p />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="grid-item">
                                                            <div id="hgr6Y" className="cnt-box-top-icon    cnt-box hc-cmp-content-box  boxed scroll-to " style={{ textAlign: 'justify' }} data-href="#">
                                                                <i className=" im-find-user " />
                                                                <div className="caption">
                                                                    <h2>Skip Trace Module</h2>
                                                                    <p className>
                                                                    </p><li>Multiple data sources for data enrichment allows higher customer engagement</li>
                                                                    <li>Data enhancement through proprietary skip trace module</li>
                                                                    <p />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel">
                                                <div id="39fNN" className="hc-component hc-cmp-grid-list grid-list   " data-columns={4} data-columns-lg={2} data-columns-md={1} data-columns-sm={1} data-columns-xs={1}>
                                                    <div className="grid-box">
                                                        <div className="grid-item">
                                                            <div id="Puyrg" className="cnt-box-top-icon    cnt-box hc-cmp-content-box  boxed scroll-to " data-href="#">
                                                                <i className=" im-monitor-2 " />
                                                                <div className="caption">
                                                                    <h2>Smart Software</h2>
                                                                    <p className>Duis aute irure dolor in reprehenderita ineto.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="grid-item">
                                                            <div id="EH2BI" className="cnt-box-top-icon    cnt-box hc-cmp-content-box  boxed scroll-to " data-href="#">
                                                                <i className=" im-bar-chart2 " />
                                                                <div className="caption">
                                                                    <h2>Analytics Based</h2>
                                                                    <p className>Duis aute irure dolor in reprehenderita ineto.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="grid-item">
                                                            <div id="h5sO3" className="cnt-box-top-icon    cnt-box hc-cmp-content-box  boxed scroll-to " data-href="#">
                                                                <i className=" im-medal-2 " />
                                                                <div className="caption">
                                                                    <h2>Industry Proven</h2>
                                                                    <p className>Duis aute irure dolor in reprehenderita ineto.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="grid-item">
                                                            <div id="owc4D" className="cnt-box-top-icon    cnt-box hc-cmp-content-box  boxed scroll-to " data-href="#">
                                                                <i className=" im-business-woman " />
                                                                <div className="caption">
                                                                    <h2>Extensive Experience</h2>
                                                                    <p className>Duis aute irure dolor in reprehenderita ineto.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section id="section_5PR6L" className="section-base hc-cmp-section    ">
                            <div className="container mngsliderimagres">
                                <div className="row">
                                    <div id="column_zSgSF" className="hc-cmp-column hc-column col-lg-12 align-items-center  " style={{}} data-anima="fade-bottom" data-time={2000}>
                                        <div id="column_ikGxZ" className="hc-cmp-column hc-column col-lg-6 col-md-6  " style={{}}>
                                            <p id="zilSA" className="hc-cmp-text-block    " style={{}}>
                                            </p><div id="box-4_wrapper" className="rev_slider_wrapper fullwidthbanner-container" data-source="gallery" style={{ margin: '0px auto', background: 'transparent', padding: '0px', marginTop: '0px', marginBottom: '0px' }}>
                                                {/* START REVOLUTION SLIDER 5.4.8.3 auto mode */}

                                                <div className='divImageDashImg'>
                                                    <img src="wp-content/uploads/2019/06/dashboard-2.jpg" alt="" />
                                                    <img src="wp-content/uploads/2019/06/dashboard-1.jpg" alt="" />
                                                </div>

                                            </div>{/* END REVOLUTION SLIDER */}<p />
                                        </div>
                                        <div id="column_Ob2Yv" className="hc-cmp-column hc-column col-lg-6 col-md-6  " style={{}}>
                                            <div id="9j4x4" className="hc-cmp-subtitle title     ">
                                                <h2>Innovative Dashboard</h2>
                                                <p>ANALYTICS TOOLS</p>
                                            </div>
                                            <p id="ZDZ0P" className="hc-cmp-text-block    " style={{ textAlign: 'justify' }}>Our interactive
                                                dashboard allows in-depth performance monitoring at all levels (up to field agent
                                                level). The dashboard also gives exhaustive view on all the initiatives on customers.
                                            </p><a id="yqJCj" className="hc-cmp-button     btn btn-xs " href="#">Learn
                                                more</a>
                                        </div>
                                    </div>
                                    <div id="column_DoA0Z" className="hc-cmp-column hc-column col-lg-12 no-margin  " style={{}}>
                                        <hr id="JDILv" className="hc-cmp-space space-md    " />
                                    </div>
                                    <div id="column_NuDgR" className="hc-cmp-column hc-column col-lg-12 no-margin-md align-items-center  " style={{}} data-anima="fade-bottom" data-time={2000}>
                                        <div id="Ep2iP" className="hc-cmp-column hc-column col-lg-6 col-md-6 no-margin-md  " style={{}}>
                                            <div id="umnui" className="hc-cmp-subtitle title     ">
                                                <h2>Available Everywhere</h2>
                                                <p>MOBILE READY</p>
                                            </div>
                                            <p id="dvDCn" className="hc-cmp-text-block    " style={{ textAlign: 'justify' }}>The collection
                                                management system is mobile friendly which allows clients to be on top of their numbers
                                                all the time. Our in-house android app for field visits allows field agents to be
                                                updated on all the relevant loan and customer details.</p><a id="2HDpf" className="hc-cmp-button     btn btn-border btn-xs " href="#">Learn
                                                    more</a>
                                        </div>
                                        <div id="mNVlD" className="hc-cmp-column hc-column col-lg-6 col-md-6  " style={{}}>
                                            <p id="iVEZO" className="hc-cmp-text-block    " style={{}}>
                                            </p><div id="box-3_wrapper" className="rev_slider_wrapper fullwidthbanner-container" data-source="gallery" style={{ margin: '0px auto', background: 'transparent', padding: '0px', marginTop: '0px', marginBottom: '0px' }}>
                                                {/* START REVOLUTION SLIDER 5.4.8.3 auto mode */}
                                                <div className='divImageDashImgSecond'>
                                                    <img src="wp-content/uploads/2019/06/11-1-1.jpg" alt="" />
                                                    <img src="wp-content/uploads/2019/06/dashboard-2-1.jpg" alt="" />
                                                </div>
                                            </div>{/* END REVOLUTION SLIDER */}<p />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section id="section_4CoA9" className="section-base hc-cmp-section pointsectionmngana  section-color   ">
                            <div className="container mngsliderimagres">
                                <div className="row">
                                    <div id="column_VbT60" className="hc-cmp-column hc-column col-lg-6  " style={{}} data-anima="fade-bottom" data-time={2000}>
                                        <div id="PhSc2" className="hc-cmp-subtitle title     ">
                                            <h2>Engagement Process</h2>
                                            <p>OUR FORMULA</p>
                                        </div>
                                    </div>
                                    <div id="column_OJ5KY" className="hc-cmp-column hc-column col-lg-6 align-right align-left-md  " style={{}} data-anima="fade-bottom" data-time={2000}>
                                        <hr id="7UaT5" className="hc-cmp-space space-sm  hidden-md   " />
                                        <a id="vkMA9" className="hc-cmp-button   active  btn-text " href="/about"> More about
                                            us</a>
                                    </div>
                                    <div id="column_Rt5r8" className="hc-cmp-column hc-column col-lg-12 no-margin  " style={{}} data-anima="fade-bottom" data-time={2000}>
                                        <hr id="jfdqc" className="hc-cmp-space space    " />
                                        <div id="O0OoK" data-columns className="hc-component hc-cmp-steps box-steps     ">
                                            <div className="step-item"><span>1</span>
                                                <div className="content">
                                                    <h3>Onboarding &amp; Diagnostics</h3>
                                                    <div>
                                                        <p id="arWBq" className="hc-cmp-text-block    " style={{ textAlign: 'justify' }}>
                                                            Onboarding includes panel access, SPOC, target finalization and finalizing
                                                            overall review mechanism. We carry detailed investigation into data and
                                                            existing collection practices for us to formularise way forward.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="step-item"><span>2</span>
                                                <div className="content">
                                                    <h3>Interventions</h3>
                                                    <div>
                                                        <p id="P9bmQ" className="hc-cmp-text-block    " style={{ textAlign: 'justify' }}>These
                                                            vary from digital engagement, campaigns, calling or field intervention. </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="step-item"><span>3</span>
                                                <div className="content">
                                                    <h3>Periodic Review &amp; Client Engagement</h3>
                                                    <div>
                                                        <p id="ThIEW" className="hc-cmp-text-block    " style={{ textAlign: 'justify' }}>As a
                                                            company we thrive on periodic review with clients as it allows us to problem
                                                            solve issues at hand.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* <section id="section_fpviq" className="section-base hc-cmp-section    ">
                        <div className="container mngsliderimagres">
                            <div className="row">
                                <div id="column_2ISmd" className="hc-cmp-column hc-column col-lg-12  " style={{}} data-anima="fade-bottom" data-time={2000}>
                                    <table id="hGpu8" className="hc-component hc-cmp-grid-table table table-grid table-border    table-logos table-full-sm" style={{}}>
                                        <tbody>
                                            <tr>
                                                <td><a id="7F5oA" className="hc-cmp-image-box img-box   img-png  scroll-to " href="#"><img src="wp-content/uploads/2021/03/liquiloans_website.jpg" alt="" data-width={374} data-height={103} /></a></td>
                                                <td><a id="BKWCI" className="hc-cmp-image-box img-box   img-png  scroll-to " href="#"><img src="wp-content/uploads/2021/03/earlysalary_website.jpg" alt="" data-width={374} data-height={103} /></a></td>
                                                <td><a id="xIZEi" className="hc-cmp-image-box img-box   img-png  scroll-to " href="#"><img src="wp-content/uploads/2021/03/kissht_website.jpg" alt="" data-width={374} data-height={103} /></a></td>
                                                <td><a id="OTtmF" className="hc-cmp-image-box img-box   img-png  scroll-to " href="#"><img src="wp-content/uploads/2021/03/DMI_WEBSITE.jpg" alt="" data-width={374} data-height={103} /></a></td>
                                                <td><a id="kUUch" className="hc-cmp-image-box img-box   img-png  scroll-to " href="#"><img src="wp-content/uploads/2021/03/NIRA_website.jpg" alt="" data-width={374} data-height={103} /></a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div id="column_fk914" className="hc-cmp-column hc-column col-lg-12 no-margin  " style={{}}>
                                    <p id="D8eY8" className="hc-cmp-text-block    " style={{}} />
                                </div>
                            </div>
                        </div>
                    </section> */}
                        <section className='clientslider container-fluid'>
                            <div className="slider">
                                <div className="slide-track">
                                    <div className="slide">
                                        <img src="wp-content/uploads/logo/bob.png" alt="" />
                                    </div>

                                    <div className="slide">
                                        <img src="wp-content/uploads/logo/Yes_Bank_logo.png" alt="" />
                                    </div>

                                    <div className="slide">
                                        <img src="wp-content/uploads/logo/icici.png" alt="" />
                                    </div>

                                    <div className="slide">
                                        <img src="wp-content/uploads/logo/mpokket.png" alt="" />
                                    </div>

                                    <div className="slide">
                                        <img src="wp-content/uploads/logo/moneyView.png" alt="" />
                                    </div>

                                    <div className="slide">
                                        <img src="wp-content/uploads/logo/slice.png" alt="" />
                                    </div>

                                    {/* <div className="slide">
                                        <img src="wp-content/uploads/logo/smpl.png" alt="" />
                                    </div> */}

                                    <div className="slide">
                                        <img src="wp-content/uploads/logo/bhanix-finance.png" alt="" />
                                    </div>

                                    <div className="slide">
                                        <img src="wp-content/uploads/logo/emiratesnbd.png" alt="" />
                                    </div>

                                    <div className="slide">
                                        <img src="wp-content/uploads/logo/true_credit.png" alt="" />
                                    </div>

                                    {/* <div className="slide">
                                        <img src="wp-content/uploads/logo/hdfc.png" alt="" />
                                    </div> */}

                                    <div className="slide">
                                        <img src="wp-content/uploads/logo/krazybee.png" alt="" />
                                    </div>

                                    <div className="slide">
                                        <img src="wp-content/uploads/logo/fatakpayLogo.jpg" alt="" />
                                    </div>

                                    <div className="slide">
                                        <img src="wp-content/uploads/logo/kissht.jpg" alt="" />
                                    </div>

                                    <div className="slide">
                                        <img src="wp-content/uploads/logo/indusind-bank1.png" alt="" />
                                    </div>

                                    <div className="slide">
                                        <img src="wp-content/uploads/logo/TrustPaisa.jpg" alt="" />
                                    </div>

                                    <div className="slide">
                                        <img src="wp-content/uploads/logo/stucred.jpg" alt="" />
                                    </div>

                                    {/* <div className="slide">
                                        <img src="wp-content/uploads/logo/moneytap.png" alt="" />
                                    </div> */}

                                    <div className="slide">
                                        <img src="wp-content/uploads/logo/southindianbank1.png" alt="" />
                                    </div>

                                    <div className="slide">
                                        <img src="wp-content/uploads/logo/dmi.png" alt="" />
                                    </div>

                                    <div className="slide">
                                        <img src="wp-content/uploads/logo/cashe.png" alt="" />
                                    </div>

                                </div>
                            </div>
                        </section>

                    </main>
                </div>

            </div>
            <FooterPart />
        </>
    )
}

export default Home;