import React from 'react';
import FooterPart from '../FooterPart';
import HeadPart from '../HeadPart';

function GrievanceRedressal() {
  return (
    <>
      <HeadPart />
      <div>
        <header id="header" className="hc-cmp-header hc-component header-imagePass" style={{ backgroundImage: 'url(../wp-content/uploads/2019/06/hd-wide-6-1920x500.jpg)' }}>
          <div className="container header-imagePass-heading">
            <h1>GRIEVANCE REDRESSAL MECHANISM</h1>
          </div>
        </header>
        <section id="section_5ZtkF" className="section-base hc-cmp-section grievanceanalytics   ">
          <div className="container ">
            <div className="row">
              <div id="column_ZV5Ne" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                <div id="ipAEI" className="hc-cmp-subtitle title     ">
                  <h2>GRIEVANCE REDRESSAL MECHANISM</h2>
                  <p />
                </div>
                <p id="Qw85H" className="hc-cmp-text-block    " style={{}}>Customer delight is our priority and we are
                  committed to provide our customers best in class Experience. Whilst all efforts are
                  taken to give customers the best services to avoid any grievances the customers are
                  intimated that they can record their grievances; if any; in person, writing or
                  verbally. </p>
              </div>
              <div id="column_DdrnQ" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                <div id="pzlj4" className="hc-cmp-subtitle title     ">
                  <h2>TIME FRAME FOR RESPONSE</h2>
                  <p />
                </div>
                <p id="SlCBK" className="hc-cmp-text-block    " style={{}}>The turn-around time for responding to a
                  complaint is as follows:  <br />i. Fraud, Legal and cases pertaining to business practices,
                  lending and recovery and the same which need retrieval of documents and exceptionally
                  old records: four Weeks. <br /> ii. For all the complaints received from the regulator,
                  timelines as mandated by respective regulator will be adhered to. </p>
              </div>
              <div id="column_5xxsE" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                <div id="S5Yfb" className="hc-cmp-subtitle title     ">
                  <h2>MECHANISM TO HANDLE CUSTOMER COMPLAINT/ GRIEVANCES</h2>
                  <p />
                </div>
                {/* <div id="n6JPg" className="hc-cmp-subtitle title     ">
              <h2>LEVEL 1:</h2>
              <p />
            </div> */}
                <div id="KKfuT" className="hc-cmp-subtitle title     ">
                  <h2>SERVICE TOUCH POINTS ARE AS INDICATED BELOW</h2>
                  <p />
                </div>
                <p id="TQVxr" className="hc-cmp-text-block    " style={{}}>Customers who wish to provide feedback or
                  send in their complaint may use the following channels between 10:00 a.m. to 18:30 p.m.
                  (Monday to Friday) and 10:00 a.m to 17:00 p.m. on Saturdays, except public
                  holidays. <br />
                  <ul>
                    <li>Email us at <a href="mailto:Compliance@acenna.in" className="__cf_email__" data-cfemail="53103c3e233f3a323d3036133230363d3d327d3a3d">Compliance@acenna.in</a></li>
                  </ul>
                  The Company for this purpose, has framed different levels for addressing and solving
                  the Customer’s grievance/ queries by appointing primary officer/ Compliance officer & Grievance Redressal Officer/
                  Nodal Officer, the details of which is indicated below:</p>
              </div>
              <div id="column_fbM5m" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
              <p>
                  <ul className='ceocaptionmng'>
                    <li><b>Compliance & Grievance Redressal Officer</b></li>
                  </ul>
                </p>
                <p id="kqi9W" className="hc-cmp-text-block    " >
                  <ul className='ulListingMng'>
                    <li>Mr. Sandesh Gaikar</li>
                    <li>Email: <a href="mailto:operations@acenna.in" className="__cf_email__" data-cfemail="472837223526332e28293407262422292926692e29">operations@acenna.in</a></li>
                    <li>Phone: +91 7045363007</li>
                    <li>Address: 6th Flr, Tower B, Arihant Aura, Turbhe, Navi Mumbai, Maharashtra 400703</li>
                  </ul>
                  The Primary Officer, so appointed by the Company shall take all
                  necessary steps to redress and resolve the grievance and to send a response as soon as
                  possible and, in any case, not later than a maximum period of two weeks.</p>
              </div>
              <div id="column_IdHlh" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                <div id="drGO0" className="hc-cmp-subtitle title     ">
                  <h2>ESCALATION MATRIX</h2>
                  <p />
                </div>
                <p id="pim3O" className="hc-cmp-text-block    " style={{}}>In case the complaint is not resolved
                  within the given time or if the customer is dissatisfied with the response received
                  from the Company, the customer may escalate the complaint to the next level as
                  indicated below –</p>
              </div>
              <div id="column_pPRwl" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                <div id="8H9DZ" className="hc-cmp-subtitle title     ">
                  <h4>Escalation to Management </h4>
                  <p />
                </div>
                <p id="OKlMF" className="hc-cmp-text-block    " style={{}}>If the customer is not satisfied with the
                  resolution received, or if the customer does not hear from us within four weeks of the
                  Compliant raised days, the customer can contact the following channel:
                </p>
                <p>
                  <ul className='ceocaptionmng'>
                    <li><b>Chief Executive Officer</b></li>
                  </ul>
                </p>
                <ul className='ulListingMng'>
                  <li>Nilesh G Rabadia</li>
                  <li>Email: <a href="mailto:compliance@acenna.in" className="__cf_email__" data-cfemail="f1929e9c819d98909f9294b19092949f9f90df989f">compliance@acenna.in</a></li>
                  {/* <li>Phone: +91 8433992280</li> */}
                </ul>
              </div>
              <div id="column_CQvg0" className="hc-cmp-column hc-column col-lg-12  " style={{}}>

                <p id="hrd7s" className="hc-cmp-text-block    " style={{}}>An assurance is given to the customer that he would receive a
                  response within 7 business days and due efforts will be taken to resolve the complaint
                  well before that. </p>
              </div>
              <div id="column_grLX1" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                <div id="zl4BH" className="hc-cmp-subtitle title     ">
                  <h2>REPORTING TO BOARD OF DIRECTORS</h2>
                  <p />
                </div>
                <p id="52HWk" className="hc-cmp-text-block    " style={{}}>The Summary of the customer grievance
                  report along with its Status Report indicating the actions taken for resolution of the
                  complaints, shall be placed before the Board of Directors for their review on annual
                  basis by the Company Secretary / Compliance Officer of the Company. The report
                  shall contain information like, the total no. of complaints received, disposed of and
                  pending, with reasons thereof, which will be placed before the Board for information /
                  guidance.</p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <FooterPart />
    </>


  )
}

export default GrievanceRedressal;