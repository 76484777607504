import React from 'react';

function EmailSend() {
    return (
        <div>
            <meta name="viewport" content="width=device-width" />
            <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
            <table bgcolor="#fafafa" style={{ width: '100%!important', height: '100%', width:"100%", backgroundColor: '#fafafa', padding: '20px', fontFamily: '"Helvetica Neue", "Helvetica", Helvetica, Arial, "Lucida Grande", sans-serif', fontSize: '100%', lineHeight: '1.6' }}>
                <tbody>
                    <tr>
                    <td />
                    <td bgcolor="#FFFFFF" style={{ border: '1px solid #eeeeee', backgroundColor: '#ffffff', borderRadius: '5px', display: 'block!important', margin: '0 auto!important', clear: 'both!important' }}><div style={{ padding: '20px', margin: '0 auto', display: 'block' }}>
                        <table style={{ width: '100%' }}>
                            <tbody><tr>
                                <td><p style={{ textAlign: 'center', display: 'block', paddingBottom: '20px', marginBottom: '20px', borderBottom: '1px solid #dddddd' }}><img src="URLOFYOURLOGO" /></p>
                                    <h4 style={{ fontWeight: 200, fontSize: '20px', margin: '20px 0 30px 0', color: '#333333' }}>Hi Team,<br /> A demo request is received. The details are as below:</h4>
                                    <h2 style={{ fontWeight: 600, fontSize: '16px', margin: '20px 0', color: '#333333' }}>Name: Johan Deo </h2>
                                    <h2 style={{ fontWeight: 600, fontSize: '16px', margin: '20px 0', color: '#333333' }}>Email: abcdemo@gmail.com</h2>
                                    <h2 style={{ fontWeight: 600, fontSize: '16px', margin: '20px 0', color: '#333333' }}> Phone: 81813243454</h2>
                                    <h2 style={{ fontWeight: 600, fontSize: '16px', margin: '20px 0', color: '#333333' }}>Date & Time: 17/06/2023 01:30</h2>
                                    <p style={{ textAlign: 'center', display: 'block', paddingTop: '20px', fontWeight: 'bold', marginTop: '30px', color: '#666666', borderTop: '1px solid #dddddd' }}>Acenna Technology Solutions Pvt. Ltd</p></td>
                            </tr>
                            </tbody></table>
                    </div></td>
                    <td />
                </tr>
                </tbody></table>
        </div>

    )
}

export default EmailSend;
