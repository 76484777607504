import React, { useState } from 'react';
import FooterPart from '../FooterPart';
import HeadPart from '../HeadPart';
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';
import { Custom_Data } from './utilis';
import { Link } from 'react-router-dom';

function ContactUs() {
  const [checked, setChecked] = React.useState(true);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [inputField, setInputField] = useState({
    name: '',
    number: '',
    gmail: '',
    companyName: '',
    message: ''
  })

  const inputsHandler = (e) => {
    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  const submitAlrtButton = () => {
    alert("Please Click on Check Box First")
  }

  const submitButton = async () => {
    if (inputField && inputField.name || inputField.gmail) {
      const data = {
        feature: "ACENNA_WEB",
        featureType: "WEB_CONTACT_US",
        name: inputField.name,
        email: inputField.gmail,
        mobileNumber: inputField.number,
        companyName: inputField.companyName,
        comment: inputField.message
      }
      setIsLoading(true);
      let reviewDataTemp = await Custom_Data(data);
      console.log("reviewDataTempList---", reviewDataTemp)
      // setCustomerData(reviewDataTemp.data);
      setIsLoading(false);

      navigate('/Thank_you');

      // history.push({
      //     pathname: '/Thank_you',
      // });
    }
    else {
      alert("Please Enter Data")
    }

  }


  return (
    <>
      {isLoading ? <Loader /> : ""}
      <HeadPart />
      <header id="header" className="hc-cmp-header hc-component header-imagePass" style={{ backgroundImage: 'url(../wp-content/uploads/2019/06/hd-wide-6-1920x500.jpg)' }}>
        <div className="container header-imagePass-heading">
          <h1>Contact Us</h1>
        </div>
      </header>
      <main>
        <section id="section_bQeLM" className="section-base hc-cmp-section contactUsForm  section-color   ">
          <div className="container ">
            <div className="row">
              <div id="column_KPjaH" className="hc-cmp-column hc-column col-lg-6  " style={{}}>
                <div id="aotzJ" className="hc-cmp-subtitle title     ">
                  <h2>How can we help?</h2>
                  
                </div>

                <div className="row">
                  <div className="col-lg-6"><input id="Name" name="name" onChange={inputsHandler} placeholder="Name" type="text" className="input-text" required /></div>
                  <div className="col-lg-6"><input id="Mobile-Number" name="number" onChange={inputsHandler} placeholder="Mobile Number" type="text" className="input-text" required /></div>
                  <div className="col-lg-6"><input id="Work-Email" name="gmail" onChange={inputsHandler} placeholder="Work Email" type="email" className="input-text" required /></div>
                  <div className="col-lg-6"><input id="Company-Name" name="companyName" onChange={inputsHandler} placeholder="Company Name" type="text" className="input-text" required /></div>
                  <div className="col-lg-12"><textarea id="Your-Message" name="message" onChange={inputsHandler} placeholder="Your Message" className="input-textarea" required defaultValue={""} /></div>
                </div>
                <div className="form-checkbox"><input type="checkbox" id="check" name="check" defaultChecked={checked}
                  onChange={() => setChecked((state) => !state)} required /><label htmlFor="check">I accept the terms of service and the <Link to="/privacy-policy">privacy
                    policy</Link></label></div>

                {
                  checked == true ? (<div className='contactSendMsg'>
                    <button className="btn btn-xs" onClick={submitButton} type="submit">Send message</button>
                  </div>) :
                    (<div className='contactSendMsg'>
                      <button className="btn btn-xs" onClick={submitAlrtButton} type="button">Send message</button>
                    </div>)
                }



              </div>
              <div id="column_e9Fnr" className="hc-cmp-column hc-column col-lg-6" style={{}}>
                <hr id="0RiKW" className="hc-cmp-space space  visible-md   " />
                <div id="column_5fGYH" className="hc-cmp-column hc-column margintopmsng col-lg-12 boxed-area  " style={{}}>
                  <h3 id="uyODP" className="hc-cmp-title-tag    " style={{}}>CONTACTS</h3>
                  <p id="s8bvT" className="hc-cmp-text-block    " style={{}}>Come to visit us and discuss your
                    project in our conference room.</p>

                  <div className='addressNameContact'>
                    <h4>Registered Address:</h4>
                    <p>2A, Lentin Chambers, Fort, Mumbai 400001</p>
                  </div>

                  <div className='addressNameContact'>
                    <h4>Operational Address:</h4>
                    <p>2nd Floor, Krishna Business Park, Opp. Digha Railway Station, Airoli, Navi Mumbai 400708</p>
                  </div>

                  <ul id="9Jp2x" className="hc-cmp-text-list text-list text-list-line    ">
                    {/* <li><b>Registered Address</b> <hr /> <br/>
                    <p>2A, Lentin Chambers, Fort, Mumbai 400001</p>
                  </li>
                
                  <li><b>Operational Address</b> <hr /> <br/>
                    
                    <p>6th Flr, Tower B, Arihant Aura, Turbhe, Navi Mumbai -- 400703</p>
                  </li> */}

                    <li><b>Email</b>
                      <hr />
                      <p><a href="mailto:info@acenna.in" className="__cf_email__" >info@acenna.in</a>
                      </p>
                    </li>
                    <li><b>Phone</b>
                      <hr />
                      <p>022-22657214</p>
                    </li>
                    <li><b>Opening Hours</b>
                      <hr />
                      <p>9AM - 7PM Mon - Sat</p>
                    </li>
                  </ul>
                  <hr id="QGa7x" className="hc-cmp-space space-sm    " />
                  {/* <div id="qbVMZ" className="hc-cmp-social-share-buttons icon-links icon-social social-colors icon-links-grid      ">
                  <a data-link="share-linkedin" target="_blank" href="#" className="linkedin"><i className="icon-linkedin" /></a>
                </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className='row mapdirectionmng'>
            <div className='col-md-6'>
              <h4 className='text-centerMapmng'>Registered Address</h4>
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe width="100%" height={400} id="gmap_canvas" src="https://maps.google.com/maps?q=acenna&t=&z=15&ie=UTF8&iwloc=&output=embed" frameBorder={0} scrolling="no" marginHeight={0} marginWidth={0} /><a href="https://embedgooglemap.net/124/">world war z 123movies</a><br /><style dangerouslySetInnerHTML={{ __html: ".mapouter{position:relative;text-align:right;height:400px;width:100%;}" }} /><a href="https://www.embedgooglemap.net" /><style dangerouslySetInnerHTML={{ __html: ".gmap_canvas {overflow:hidden;background:none!important;height:400px;width:100%;}" }} />
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <h4 className='text-centerMapmng'>Operational Address</h4>
              <div className='mapouter'>
                <div className="gmap_canvas">
                  <iframe width="100%" height={400} id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.297417305801!2d72.9932571741702!3d19.182207782044355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7bf3a57cb01ef%3A0x8e4b1073f31f2305!2sKrishna%20Business%20Park%20Mukund!5e0!3m2!1sen!2sin!4v1732898960397!5m2!1sen!2sin" frameBorder={0} scrolling="no" marginHeight={0} marginWidth={0} /><a href="https://embedgooglemap.net/124/">world war z 123movies</a><br /><style dangerouslySetInnerHTML={{ __html: ".mapouter{position:relative;text-align:right;height:400px;width:100%;}" }} /><a href="https://www.embedgooglemap.net" /><style dangerouslySetInnerHTML={{ __html: ".gmap_canvas {overflow:hidden;background:none!important;height:400px;width:100%;}" }} />
                </div>
              </div>

            </div>
          </div>

        </section>
      </main>
      <FooterPart />
    </>

  )
}

export default ContactUs;