import React from 'react';
import FooterPart from '../FooterPart';
import HeadPart from '../HeadPart';

function PrivacyPolicy() {
    return (
        <>
        <HeadPart />
            <div>
                <header id="header"  className="hc-cmp-header hc-component header-imagePass" style={{backgroundImage: 'url(../wp-content/uploads/2019/06/hd-wide-1-1920x500.jpg)'}}>
                    <div className="container someheaderBanner">
                        <h1 className='policymngChanges'>Privacy Policy</h1>
                        {/* <ol className="breadcrumb ">
                            <li><a href="/">Home</a></li>
                            <li className="active"><a href="#">Privacy Policy</a></li>
                        </ol> */}
                    </div>
                </header>
                <main>
                    <section id="section_5ZtkF" className="section-base hc-cmp-section    ">
                        <div className="container ">
                            <div className="row">
                                <div id="column_QmN4L" className="hc-cmp-column hc-column col-lg-12  " style={{ marginTop: '-55px', marginBottom: '18px' }}>
                                    <div id="FmrEm" className="hc-cmp-subtitle title     ">
                                        <h2>Privacy Policy</h2>
                                        <p />
                                    </div>
                                </div>
                                <div id="column_NJvAz" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                                    <p id="ZB6zL" className="hc-cmp-text-block    " style={{ textAlign: 'justify' }}>This Privacy Policy
                                        describes our policies and procedures on the collection, use and disclosure of your
                                        information when you use the service and tells you about your privacy rights and how the law
                                        protects you.<br />We use your personal data to provide and improve the service. By using
                                        the service, you agree to the collection and use of information in accordance with this
                                        Privacy Policy. This Privacy Policy has been created with the help of the Privacy Policy
                                        Generator<br /></p>
                                </div>
                                <div id="column_PyWvB" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                                    <div id="nkLfR" className="hc-cmp-subtitle title     ">
                                        <h2>Interpretation and Definitions</h2>
                                        <p />
                                    </div>
                                    <h4 id="uvsws" className="hc-cmp-title-tag    " style={{ marginTop: '-20px' }}>
                                        Interpretation</h4>
                                    <p id="EVqfF" className="hc-cmp-text-block    " style={{ textAlign: 'justify', marginTop: '0px' }}>The words of which the initial letter
                                        is capitalized have meanings defined under the following conditions. The following
                                        definitions shall have the same meaning regardless of whether they appear in singular or in
                                        plural.</p>
                                    <h4 id="L49oO" className="hc-cmp-title-tag    " >Definitions</h4>
                                    <p id="GSabR" className="hc-cmp-text-block    " style={{ marginTop: '0px' }}>For the purposes of this
                                        Privacy Policy:</p>
                                    <p id="uCnDo" className="hc-cmp-text-block    " style={{ textAlign: 'justify', marginLeft: '53px' }}>
                                        <b>Account</b> means a unique account created for You to access our Service or parts of our
                                        Service.<br /><b>Company</b> (referred to as either "the Company", "We", "Us" or "Our" in
                                        this Agreement) refers to Acenna, 2A, 4th Floor, Lentin Chambers, Dalal Street, Fort,
                                        Mumbai, Mumbai City, Maharashtra. <br /><b>Cookies</b> are small files that are placed on
                                        Your computer, mobile device or any other device by a website, containing the details of
                                        Your browsing history on that website among its many uses.<br /><b>Country</b> refers to
                                        Maharashtra, India<br /><b>Device</b> means any device that can access the Service such as a
                                        computer, a cellphone or a digital tablet.<br /><b>Personal Data </b>is any information that
                                        relates to an identified or identifiable individual.<br /><b>Service</b> refers to the
                                        Website.<br /><b>Service Provider</b> means any natural or legal person who processes the
                                        data on behalf of the Company. It refers to third-party companies or individuals employed by
                                        the Company to facilitate the Service, to provide the Service on behalf of the Company, to
                                        perform services related to the Service or to assist the Company in analyzing how the
                                        Service is used.<br /><b>Third-party Social Media Service</b> refers to any website or any
                                        social network website through which a User can log in or create an account to use the
                                        Service.<br /><b>Usage Data</b> refers to data collected automatically, either generated by
                                        the use of the Service or from the Service infrastructure itself (for example, the duration
                                        of a page visit).<br /><b>Website </b>refers to Acenna Collect, accessible from 
                                        <a class="linkacennaPro" href='https://www.acenna.in/'> https://www.acenna.in/</a><br /><b>You</b> means the individual accessing or using the Service, or
                                        the company, or other legal entity on behalf of which such individual is accessing or using
                                        the Service, as applicable.<br /></p>
                                    <div id="PzZwo" className="hc-cmp-subtitle title     ">
                                        <h2>Collecting and Using Your Personal Data</h2>
                                        <p />
                                    </div>
                                    <h4 id="IwUH7" className="hc-cmp-title-tag" style={{ marginTop: '-20px', fontSize:"18px"}}>Types
                                        of Data Collected</h4>
                                    <p id="ikVbH" className="hc-cmp-text-block    " style={{ textAlign: 'justify' }}><b >Personal Data</b><br />While using Our Service, We may ask You
                                        to provide Us with certain personally identifiable information that can be used to contact
                                        or identify You. Personally identifiable information may include, but is not limited
                                        to:<br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Email Address<br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Contact Number<br /><b>Usage Data</b><br />Usage Data is collected automatically when
                                        using the Service.<br />Usage Data may include information such as Your Device's Internet
                                        Protocol address (i.e. IP address), browser type, browser version, the pages of our Service
                                        that You visit, the time and date of Your visit, the time spent on those pages, unique
                                        device identifiers and other diagnostic data.<br />When You access the Service by or through
                                        a mobile device, We may collect certain information automatically, including, but not
                                        limited to, the type of mobile device You use, Your mobile device unique ID, the IP address
                                        of Your mobile device, Your mobile operating system, the type of mobile Internet browser You
                                        use, unique device identifiers and other diagnostic data.<br />We may also collect
                                        information that Your browser sends whenever You visit our Service or when You access the
                                        Service by or through a mobile device.<br /><b>Tracking Technologies
                                            and Cookies</b><br />We use Cookies and similar tracking technologies to track the
                                        activity on Our Service and store certain information. Tracking technologies used are
                                        beacons, tags, and scripts to collect and track information and to improve and analyze Our
                                        Service. The technologies We use may include:<br /><br /></p>
                                    <p id="ubrdl" className="hc-cmp-text-block    " style={{ marginTop: '-20px', marginLeft: '53px' }}>
                                        <b>Cookies or Browser Cookies.</b> A cookie is a small file placed on Your Device. You can
                                        instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent.
                                        However, if You do not accept Cookies, You may not be able to use some parts of our Service.
                                        Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service
                                        may use Cookies.<br /><b>Flash Cookies.</b> Certain features of our Service may use local
                                        stored objects (or Flash Cookies) to collect and store information about Your preferences or
                                        Your activity on our Service. Flash Cookies are not managed by the same browser settings as
                                        those used for Browser Cookies. For more information on how You can delete Flash Cookies,
                                        please read "Where can I change the settings for disabling, or deleting local shared
                                        objects?" available at
                                        https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_<br /><b>Web
                                            Beacons.</b> Certain sections of our Service and our emails may contain small electronic
                                        files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel
                                        gifs) that permit the Company, for example, to count users who have visited those pages or
                                        opened an email and for other related website statistics (for example, recording the
                                        popularity of a certain section and verifying system and server integrity).<br /></p>
                                    <p id="TX5N1" className="hc-cmp-text-block    " style={{ textAlign: 'justify' }}>Cookies can be
                                        "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or
                                        mobile device when You go offline, while Session Cookies are deleted as soon as You close
                                        Your web browser. You can learn more about cookies here: All About Cookies by
                                        TermsFeed.<br />We use both Session and Persistent Cookies for the purposes set out below:
                                    </p>
                                    <p id="k3lwr" className="hc-cmp-text-block    " style={{ marginLeft: '53px', textAlign: 'justify' }}><b>Necessary / Essential
                                        Cookies</b><br />Type: Session Cookies<br />Administered by: Us<br />Purpose: These
                                        Cookies are essential to provide You with services available through the Website and to
                                        enable You to use some of its features. They help to authenticate users and prevent
                                        fraudulent use of user accounts. Without these Cookies, the services that You have asked for
                                        cannot be provided, and We only use these Cookies to provide You with those
                                        services.<br /><b>Cookies Policy / Notice Acceptance Cookies</b><br />Type: Persistent
                                        Cookies<br />Administered by: Us<br />Purpose: These Cookies identify if users have accepted
                                        the use of cookies on the Website.<br /><b>Functionality Cookies</b><br />Type: Persistent
                                        Cookies<br />Administered by: Us<br />Purpose: These Cookies allow us to remember choices
                                        You make when You use the Website, such as remembering your login details or language
                                        preference. The purpose of these Cookies is to provide You with a more personal experience
                                        and to avoid You having to re-enter your preferences every time You use the Website.<br />
                                    </p>
                                    <p id="gCe2I" className="hc-cmp-text-block    " style={{ textAlign: 'justify' }}>For more information
                                        about the cookies we use and your choices regarding cookies, please visit our Cookies Policy
                                        or the Cookies section of our Privacy Policy.<br /><b>Use of Your
                                            Personal Data</b><br />The Company may use Personal Data for the following
                                        purposes:<br /></p>
                                    <p id="PbIOf" className="hc-cmp-text-block    " style={{ textAlign: 'justify', marginLeft: '53px' }}><b>To
                                        provide and maintain our Service,</b> including to monitor the usage of our
                                        Service.<br /><b>To manage Your Account:</b> to manage Your registration as a user of the
                                        Service. The Personal Data You provide can give You access to different functionalities of
                                        the Service that are available to You as a registered user.<br /><b>For the performance of a
                                            contract:</b> the development, compliance and undertaking of the purchase contract for
                                        the products, items or services You have purchased or of any other contract with Us through
                                        the Service.<br /><b>To contact You:</b> To contact You by email, telephone calls, SMS, or
                                        other equivalent forms of electronic communication, such as a mobile application's push
                                        notifications regarding updates or informative communications related to the
                                        functionalities, products or contracted services, including the security updates, when
                                        necessary or reasonable for their implementation.<br /><b>To provide You</b> with news,
                                        special offers and general information about other goods, services and events which we offer
                                        that are similar to those that you have already purchased or enquired about unless You have
                                        opted not to receive such information.<br /><b>To manage Your requests:</b> To attend and
                                        manage Your requests to Us.<br /><b>For business transfers:</b> We may use Your information
                                        to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or
                                        other sale or transfer of some or all of Our assets, whether as a going concern or as part
                                        of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about
                                        our Service users is among the assets transferred.<br /><b>For other purposes:</b> We may
                                        use Your information for other purposes, such as data analysis, identifying usage trends,
                                        determining the effectiveness of our promotional campaigns and to evaluate and improve our
                                        Service, products, services, marketing and your experience.<br /></p>
                                </div>
                                <div id="column_DUSlf" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                                    <h4 id="98Nsh" className="hc-cmp-title-tag    " style={{ marginTop: '14px', color: '#404D60 !important' }}>We may share Your personal
                                        information in the following situations:</h4>
                                </div>
                                <div id="column_duLLf" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                                    <p id="WHMlc" className="hc-cmp-text-block    " style={{ textAlign: 'justify' }}><b>With Service
                                        Providers:</b> We may share Your personal information with Service Providers to monitor
                                        and analyze the use of our Service, to contact You.<br /><b>For business transfers:</b> We
                                        may share or transfer Your personal information in connection with, or during negotiations
                                        of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our
                                        business to another company.<br /><b>With Affiliates:</b> We may share Your information with
                                        Our affiliates, in which case we will require those affiliates to honor this Privacy Policy.
                                        Affiliates include Our parent company and any other subsidiaries, joint venture partners or
                                        other companies that We control or that are under common control with Us.<br /><b>With
                                            business partners:</b> We may share Your information with Our business partners to offer
                                        You certain products, services or promotions.<br /><b>With other users:</b> when You share
                                        personal information or otherwise interact in the public areas with other users, such
                                        information may be viewed by all users and may be publicly distributed outside. If You
                                        interact with other users or register through a Third-Party Social Media Service, Your
                                        contacts on the Third-Party Social Media Service may see Your name, profile, pictures and
                                        description of Your activity. Similarly, other users will be able to view descriptions of
                                        Your activity, communicate with You and view Your profile.<br /><b>With Your consent:</b> We
                                        may disclose Your personal information for any other purpose with Your consent.<br /></p>
                                </div>
                                <div id="column_I1SNH" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                                    <p id="gOz40" className="hc-cmp-text-block    " style={{ marginTop: '16px', textAlign: 'justify' }}>
                                        <b >Retention of Your Personal Data</b><br />The Company will retain
                                        Your Personal Data only for as long as is necessary for the purposes set out in this Privacy
                                        Policy. We will retain and use Your Personal Data to the extent necessary to comply with our
                                        legal obligations (for example, if we are required to retain your data to comply with
                                        applicable laws), resolve disputes, and enforce our legal agreements and policies.<br />The
                                        Company will also retain Usage Data for internal analysis purposes. Usage Data is generally
                                        retained for a shorter period of time, except when this data is used to strengthen the
                                        security or to improve the functionality of Our Service, or We are legally obligated to
                                        retain this data for longer time periods.<br /><b >Transfer of Your
                                            Personal Data</b><br />Your information, including Personal Data, is processed at the
                                        Company's operating offices and in any other places where the parties involved in the
                                        processing are located. It means that this information may be transferred to — and
                                        maintained on — computers located outside of Your state, province, country or other
                                        governmental jurisdiction where the data protection laws may differ than those from Your
                                        jurisdiction.<br />Your consent to this Privacy Policy followed by Your submission of such
                                        information represents Your agreement to that transfer.<br />The Company will take all steps
                                        reasonably necessary to ensure that Your data is treated securely and in accordance with
                                        this Privacy Policy and no transfer of Your Personal Data will take place to an organization
                                        or a country unless there are adequate controls in place including the security of Your data
                                        and other personal information.<br /><b >Disclosure of Your Personal
                                            Data</b><br /><strong>Business Transactions</strong><br />If the Company is involved in
                                        a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide
                                        notice before Your Personal Data is transferred and becomes subject to a different Privacy
                                        Policy.<br /><strong>Law enforcement</strong><br />Under certain circumstances, the Company
                                        may be required to disclose Your Personal Data if required to do so by law or in response to
                                        valid requests by public authorities (e.g. a court or a government
                                        agency).<br /><strong>Other legal requirements</strong><br />The Company may disclose Your
                                        Personal Data in the good faith belief that such action is necessary to:<br /><b >Other legal requirements</b><br />The Company may disclose Your
                                        Personal Data in the good faith belief that such action is necessary to:<br /><br /><b style={{ fontSize: '19px' }}>•</b>&nbsp;&nbsp; Comply with a legal obligation<br /><b style={{ fontSize: '19px' }}>•</b>&nbsp;&nbsp; Protect and defend the rights or property of the
                                        Company<br /><b style={{ fontSize: '19px' }}>•</b>&nbsp;&nbsp; Prevent or investigate possible wrongdoing
                                        in connection with the Service<br /><b style={{ fontSize: '19px' }}>•</b>&nbsp;&nbsp; Protect the personal
                                        safety of Users of the Service or the public<br /><b style={{ fontSize: '19px' }}>•</b>&nbsp;&nbsp;
                                        Protect against legal liability<br /><b >Security of Your Personal
                                            Data</b><br />The security of Your Personal Data is important to Us, but remember that
                                        no method of transmission over the Internet, or method of electronic storage is 100% secure.
                                        While We strive to use commercially acceptable means to protect Your Personal Data, We
                                        cannot guarantee its absolute security.</p>
                                </div>
                                <div id="column_EciqI" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
                                    <div id="jCUWd" className="hc-cmp-subtitle title     ">
                                        <h2>Links to Other Websites</h2>
                                        <p />
                                    </div>
                                    <p id="nRB4x" className="hc-cmp-text-block    " style={{ textAlign: 'justify', marginTop: '-18px' }}>
                                        Our Service may contain links to other websites that are not operated by Us. If You click on
                                        a third party link, You will be directed to that third party's site. We strongly advise You
                                        to review the Privacy Policy of every site You visit.<br />We have no control over and
                                        assume no responsibility for the content, privacy policies or practices of any third party
                                        sites or services.<br /></p>
                                    <div id="BGGBb" className="hc-cmp-subtitle title     ">
                                        <h2>Changes to this Privacy Policy</h2>
                                        <p />
                                    </div>
                                    <p id="6a12h" className="hc-cmp-text-block    " style={{ textAlign: 'justify', marginTop: '-18px' }}>
                                        We may update Our Privacy Policy from time to time. We will notify You of any changes by
                                        posting the new Privacy Policy on this page.<br />We will let You know via email and/or a
                                        prominent notice on Our Service, prior to the change becoming effective and update the "Last
                                        updated" date at the top of this Privacy Policy.<br />You are advised to review this Privacy
                                        Policy periodically for any changes. Changes to this Privacy Policy are effective when they
                                        are posted on this page.<br /></p>
                                    <div id="YqqP4" className="hc-cmp-subtitle title     ">
                                        <h2>Contact Us</h2>
                                        <p />
                                    </div>
                                    <p id="bEyD3" className="hc-cmp-text-block    " style={{ textAlign: 'justify', marginTop: '-18px' }}>
                                        If you have any questions about this Privacy Policy, You can contact us:<br /><b style={{ fontSize: '19px' }}>•</b>&nbsp;&nbsp;&nbsp;&nbsp;By email: <a href="mailto:info@acenna.in" className="__cf_email__" >info@acenna.in</a><br /></p>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
            <FooterPart />
        </>


    )
}

export default PrivacyPolicy;