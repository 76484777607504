import React from 'react';
import { Link } from 'react-router-dom';

function Page_Not_Found() {
    return (
        <div className="flexitems-center">
            <div>
                <div className="flex flex-col items-center pagenotFound space-y-2">
                    <img src='wp-content/uploads/2019/06/something-lost.png' alt='notFound'/>
                    <h1 className="text-4xl font-bold">Page Not Found</h1>
                    <a href="/" className="btn btn-primary acenna_backHome">Back Home</a>
                </div>
            </div>
        </div>
    )
}

export default Page_Not_Found;
