import React from 'react';
import FooterPart from '../FooterPart';
import HeadPart from '../HeadPart';
function About() {
return (
<>
<HeadPart />
<header id="header" className="hc-cmp-header hc-component header-imagePass" style={{ backgroundImage: 'url(../wp-content/uploads/2019/06/hd-wide-2-1920x500.jpg)' }}>
<div className="container someheaderBanner">
   <div className='policymngChanges'>
      <h1>About Us</h1>
      <h2 style={{color:"#fff"}}>Our core values</h2>
   </div>
</div>
</header>
<main>
   <section id="section_5ZtkF" className="section-base hc-cmp-section aboutbasedContainer">
      <div className="container ">
         <div className="row">
            <div id="column_dI1j4" className="hc-cmp-column hc-column col-lg-8  " style={{}}>
               <div id="BPmh1" className="hc-cmp-subtitle title     " style={{}}>
                  <h2>Who is Acenna?</h2>
                  <p />
               </div>
               <p id="94HcC" className="hc-cmp-text-block    " style={{ marginTop: '-18px', textAlign: 'justify' }}>We help our clients to improve overall collection &amp; recovery efficiency by more than 30% using best in class technology, data analytics driven approach, PAN India field presence and legal automation.<br />Our collection &amp; recovery platform has helped our companies to reduce their collection cost significantly. With deep focus on data analytics, clustering and customer segmentation is key to overall customer engagement and repayment journey. <br /></p>
            </div>
            <div id="column_cEo5r" className="hc-cmp-column hc-column col-lg-4  col-fit mngfitimageslider" style={{}}>
               <ul id="hxEMD" className="hc-component hc-cmp-image-slider slider  light   " data-options="arrows:true,nav:true">
                  <li><a className="img-box lightbox " href="../wp-content/uploads/2019/06/60572f9638d86.jpg" data-lightbox-anima="fade-top"><img alt="slide" src="../wp-content/uploads/2019/06/60572f9638d86.jpg" /></a></li>
                  <li><a className="img-box lightbox " href="../wp-content/uploads/2019/06/60572fcad7fa4.jpg" data-lightbox-anima="fade-top"><img alt="slide" src="../wp-content/uploads/2019/06/60572fcad7fa4.jpg" /></a></li>
                  <li><a className="img-box lightbox " href="../wp-content/uploads/2019/06/6057300bf113f.jpg" data-lightbox-anima="fade-top"><img alt="slide" src="../wp-content/uploads/2019/06/6057300bf113f.jpg" /></a></li>
               </ul>
            </div>
            <div id="column_UFysI" className="hc-cmp-column hc-column col-lg-12   hc-empty" style={{}}>
            </div>
            <div id="column_QcJCU" className="hc-cmp-column hc-column col-lg-6  " style={{}}>
               <div id="0XfDg" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '100%', marginTop: '48px' }} className="hc-cmp-image align-center   "><img src="../wp-content/uploads/2021/03/experience-team-1.jpg" alt="" />
            </div>
         </div>
         <div id="column_sbOPK" className="hc-cmp-column hc-column col-lg-6  " style={{}}>
            <div id="BuT7q" className="hc-cmp-subtitle title     " style={{ marginTop: '40px' }}>
            <h2>Experienced Team</h2>
            <p />
         </div>
         <p id="5Myad" className="hc-cmp-text-block    " style={{ textAlign: 'justify' }}>Team at Acenna has more than ~100 years of cumulative experience having served more than 12 banks and financial institutions in collection &amp; recovery </p>
      </div>
      <div id="column_NAK4l" className="hc-cmp-column hc-column col-lg-12  " style={{ marginTop: '40px' }}>
      <div id="biicE" className="hc-cmp-image   "><img src="../wp-content/uploads/2021/03/technology2.jpg" alt="" /></div>
      </div>
      <div id="column_7U9bB" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
         <div id="column_lzF0v" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
            <div id="FTx3Y" className="hc-cmp-subtitle title     ">
               <h2>Technology Led Collection</h2>
               <p />
            </div>
            <p id="DM5s1" className="hc-cmp-text-block    " style={{ textAlign: 'justify', marginTop: '-18px' }}>We have developed propriety collection module which has 6 key elements from customer contact establishment, engagement, campaigns to payment.</p>
            <div id="column_Xa7vf" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
               <div id="Uyngm" className="hc-cmp-image align-center   "><img src="../wp-content/uploads/2021/03/abt2.jpg" alt="" /></div>
            </div>
         </div>
         <div id="column_rmneV" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
            <div id="oXvbS" className="hc-cmp-subtitle title     ">
               <h2>Extensive Field Presence</h2>
               <p />
            </div>
            <p id="jCbEi" className="hc-cmp-text-block    " style={{ textAlign: 'justify', marginTop: '-18px' }}>We have built in-house team of ~150 field agents, 21 managers and 7 state heads. The field team is aided with Android App and central office.</p>
         </div>
         <div id="column_3EO1Q" className="hc-cmp-column hc-column col-lg-12  " style={{}}>
            <div id="TrAaR" className="hc-cmp-subtitle title     ">
               <h2>Customized Segmentation And Analytics Driven Campaigns</h2>
               <p />
            </div>
            <p id="GBV2E" className="hc-cmp-text-block    " style={{ textAlign: 'justify', marginTop: '-18px' }}>We design analytics driven campaigns for collection (identifying right set of customers to approach and execution). 63% chances of establishing contactability of customer through in-house engine (more than 78 data sources)</p>
         </div>
         <hr id="UoqOG" className="hc-cmp-space space  align-center   " />
         <hr id="qlveW" className="hc-cmp-space space  align-center   " />
         <table id="zh4yO" className="hc-component hc-cmp-grid-table table table-grid table-border table-6-md  align-left align-center   " style={{}}>
            <tbody>
               <tr>
                  <td>
                     <div id="EG6W5" className="hc-cmp-counter counter counter-icon counter-vertical    ">
                        <i className="text-md im-globe " />    
                        <div>
                           <h3>CITIES</h3>
                           <div className="value">
                              <span className="text-md" data-to={47}>0</span>
                              <span className>+</span>      
                           </div>
                        </div>
                     </div>
                  </td>
                  <td>
                     <div id="hWO71" className="hc-cmp-counter counter counter-icon counter-vertical    ">
                        <i className="text-md im-geo2-love " />    
                        <div>
                           <h3>PINS</h3>
                           <div className="value">
                              <span className="text-md" data-to={6000}>0</span>
                              <span className>+</span>      
                           </div>
                        </div>
                     </div>
                  </td>
                  <td>
                     <div id="O9c48" className="hc-cmp-counter counter counter-icon counter-vertical    ">
                        <i className="text-md im-business-mens " />    
                        <div>
                           <h3>core staff</h3>
                           <div className="value">
                              <span className="text-md" data-to={80}>0</span>
                              <span className>+</span>      
                           </div>
                        </div>
                     </div>
                  </td>
                  <td>
                     <div id="OjVrs" className="hc-cmp-counter counter counter-icon counter-vertical    ">
                        <i className="text-md im-affiliate " />    
                        <div>
                           <h3>higher engagement</h3>
                           <div className="value">
                              <span className="text-md" data-to={30}>0</span>
                              <span className>% ↑</span>      
                           </div>
                        </div>
                     </div>
                  </td>
               </tr>
            </tbody>
         </table>
      </div>
      </div>
      </div>
   </section>
</main>
<FooterPart />
</>
)
}
export default About;