import React,{useState} from 'react';
import { Outlet, Link, NavLink } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

const HeadPart = () => {
    const [isActiveMenu, setActiveMenu] = useState(false);
    const toggleClass = () => {
        setActiveMenu(!isActiveMenu);
    };
    return (
        <div>
            {/* <div id="preloader" /> */}
            <nav id="nav" className="scroll-change menu-classic light menu-fixed menu-transparent light align-right " data-menu-anima="fade-in">
                <div className="navHeaderStyle">
                    <div className="menu-brand">
                        <a href="/"><img className="logo-default scroll-show" src="wp-content/uploads/2019/06/finalLogo.png" alt="" style={{}} />
                            <img className="logo-retina scroll-show" src="wp-content/uploads/2019/06/finalLogo.png" alt="" style={{}} /></a><a href="/"><img className="logo-default scroll-hide" src="wp-content/uploads/2019/06/finalLogo.png" alt="" style={{}} />
                        </a>
                    </div>
                    <i className="menu-btn" onClick={toggleClass} />
                    <div className={isActiveMenu ? 'menu-cnt-active menu-cnt' : "menu-cnt"}>
                        <ul id="main-menu">
                            <li className=" "><a href="/">Home</a></li>
                            {/* <li>
                                <NavLink to="/">Home</NavLink>
                            </li> */}
                            <li className="dropdown "><a href="#">Know Us</a>
                                <ul className="dropdown-menu">
                                    <li><a href="/about">About Us</a></li>
                                    <li><NavLink to="/contact-us-2">Contact Us</NavLink></li>
                                    <li><NavLink to="/demo">Book Demo</NavLink></li>
                                    {/* <li className=" "><a href="/contact">Contact Us</a></li>
                                    <li className=" "><a href="/bookdemo">Book Demo</a></li> */}
                                </ul>
                            </li>

                            <li className="dropdown "><a href="/analytics">Analytics driven collection</a>
                                <ul className="dropdown-menu">
                                    {/* <li><Link to="/analytics#column_b278o">Data is core to overall collection</Link></li> */}
                                    <li className=" "><HashLink smooth to="/analytics#column_b278o">Data is core to overall collection</HashLink></li>
                                    <li className=" "><HashLink smooth to="/analytics#column_pw3QH">Digital Legal Services</HashLink></li>
                                    <li className=" "><HashLink smooth to="/analytics#column_387JF">PAN India field presence with best-in-class tele calling</HashLink></li>
                                    <li className=" "><HashLink smooth to="/analytics#column_AbobK">Campaign Management</HashLink></li>
                                    <li className=" "><HashLink smooth to="/analytics#column_StgsP">Enhancing customer contactability through Skip Trace</HashLink></li>
                                </ul>
                            </li>

                            {/* <li className=" "><a href="/analytics">Analytics driven collection</a></li> */}
                            <li className=" ">
                                {/* <a href="/techSolution">Technology</a> */}
                                <NavLink to="/techsoluiton">Technology</NavLink>
                            </li>
                            <li className=" ">
                                <NavLink to="/ourTeam">Team</NavLink>
                                {/* <a href="/ourTeam">Team</a> */}
                            </li>
                            <li className=" "><a target="_blank" href="https://www.acennacollect.in/auth/login">PANEL LOGIN</a></li>
                        </ul>

                    </div>
                </div>
            </nav>
        </div>
    )
}

export default HeadPart;
