import React from "react";
function Loader() {
  return (
    <div>
      <div className="mainloadercomponent">
        <img
          alt="loader"
          src="wp-content/uploads/2019/06/loader.gif"
        />
      </div>
    </div>
  );
}

export default Loader;