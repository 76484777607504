import React, { useState } from 'react';
import { Custom_Data } from './utilis';
import FooterPart from '../FooterPart';
import HeadPart from '../HeadPart';
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';

function Demo() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [inputField, setInputField] = useState({
        name: '',
        gmail: '',
        number: '',
        date: '',
        time: ''
    })

    const inputsHandler = (e) => {
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const submitButton = async () => {
        if(inputField && inputField.name || inputField.gmail){
            const data = {
                feature: "ACENNA_WEB",
                featureType: "BOOK_DEMO",
                name: inputField.name,
                email: inputField.gmail,
                mobileNumber: inputField.number,
                date: inputField.date,
                time: inputField.time
    
            }
            setIsLoading(true);
            let reviewDataTemp = await Custom_Data(data);
            console.log("reviewDataTempList---", reviewDataTemp)
            // setCustomerData(reviewDataTemp.data);
            setIsLoading(false);

            navigate('/Thank_you');
    
            // history.push({
            //     pathname: '/Thank_you',
            // });
        }
        else{
            alert("Please Enter Data")
        }
       
    }

    return (
        <>
        {isLoading ? <Loader/> : ""}
            <HeadPart />
            <header id="header" className="hc-cmp-header hc-component header-imagePass" style={{ backgroundImage: 'url(../wp-content/uploads/2019/06/hd-wide-6-1920x500.jpg)' }}>
                <div className="container header-imagePass-heading">
                    <h1>Book an appointment</h1>
                </div>
            </header>
            <main>
                <div className="container register getaQuoteRegister">

                    <div className="row">
                        <div className="col-md-3 register-left">

                            <h4>When are we open?</h4>
                            <p>Monday to Saturday: <br /> <b>9 AM - 7 PM</b></p>
                            <p>Sunday: <b>Closed</b></p>

                        </div>
                        <div className="col-md-9 register-right">
                            {/* <h4>Book an appointment</h4> */}
                            <div className="row register-form">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" onChange={inputsHandler} className="form-control" name='name' placeholder="Your Name" />
                                    </div>
                                    <div className="form-group">
                                        <input type="email" onChange={inputsHandler} className="form-control" name='gmail' placeholder="Your Email" />
                                    </div>
                                    <div className="form-group">
                                        <input type="Number" maxLength={10} onChange={inputsHandler} className="form-control" name='number' placeholder="Your Phone" />
                                    </div>
                                   
                                </div>
                                <div className="col-md-6 spantopmsngmargin">
                                    <div className="form-group">
                                        <input type="date" onChange={inputsHandler} className="form-control" name='date' placeholder="date" />
                                    </div>

                                   
                                    <div className="form-group">
                                        <select onChange={inputsHandler} name='time' className="wpcf7-form-control wpcf7-date wpcf7-validates-as-required wpcf7-validates-as-date form-control input-text">
                                            <option selected value="none">Time</option>
                                            <option value="09:00">09:00</option>
                                            <option value="09:15">09:15</option>
                                            <option value="09:30">09:30</option>
                                            <option value="09:45">09:45</option>
                                            <option value="10:00">10:00</option>
                                            <option value="10:15">10:15</option>
                                            <option value="10:30">10:30</option>
                                            <option value="10:45">10:45</option>
                                            <option value="11:00">11:00</option>
                                            <option value="11:15">11:15</option>
                                            <option value="11:30">11:30</option>
                                            <option value="11:45">11:45</option>
                                            <option value="12:00">12:00</option>
                                            <option value="12:15">12:15</option>
                                            <option value="12:30">12:30</option>
                                            <option value="12:45">12:45</option>
                                            <option value="01:00">01:00</option>
                                            <option value="01:15">01:15</option>
                                            <option value="01:30">01:30</option>
                                            <option value="01:45">01:45</option>
                                            <option value="02:00">02:00</option>
                                            <option value="02:15">02:15</option>
                                            <option value="02:30">02:30</option>
                                            <option value="02:45">02:45</option>
                                            <option value="03:00">03:00</option>
                                            <option value="03:15">03:15</option>
                                            <option value="03:30">03:30</option>
                                            <option value="03:45">03:45</option>
                                            <option value="04:00">04:00</option>
                                            <option value="04:15">04:15</option>
                                            <option value="04:30">04:30</option>
                                            <option value="04:45">04:45</option>
                                            <option value="05:00">05:00</option>
                                            <option value="05:15">05:15</option>
                                            <option value="05:30">05:30</option>
                                            <option value="05:45">05:45</option>
                                            <option value="06:00">06:00</option>
                                            <option value="06:15">06:15</option>
                                            <option value="06:30">06:30</option>
                                            <option value="06:45">06:45</option>
                                            <option value="07:00">07:00</option>
                                        </select>
                                    </div>


                                </div>
                                <div className="col-md-12 text-center">
                                    <button onClick={submitButton} type='submit' className="btnRegister" >Submit</button>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </main>
            <FooterPart />
        </>
    )
}

export default Demo;